import React from 'react';
import './index.css'; 
import { hydrate, render } from "react-dom";
import MainPage from './MainPage/MainPage';
import LoginPage from './LoginPage/LoginPage';
import Success from './StatusPayment/Success';
import ListApplicant from './ListApplicant/ListApplicant';
import SubmitForm from './SubmitForm/Form';
import ListAdmin from './ListAdmin/ListAdmin';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA  from 'react-ga4';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const App = (
  <React.StrictMode>
    <HelmetProvider>
      <Router>
          <Routes>
            <Route path="/" element={<MainPage />} />  
            <Route path="/CustForm" element={<SubmitForm />} />  
            <Route path="/StatusPayment" element={<Success />} />  
            <Route path="/LoginPage" element={<LoginPage />} />  
            <Route path="/Admin/ListApplicant" element={<ListApplicant />} />
            <Route path="/Admin/ListAdmin" element={<ListAdmin />} />
          </Routes>
        </Router>
      </HelmetProvider>
  </React.StrictMode>
);  

ReactGA.initialize("G-964V9TKL2S");
const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate(App, rootElement);
} else {
  render(App, rootElement);
}

reportWebVitals();
 