import React from 'react';
import $ from 'jquery';
import './SwalStatus.css';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { useNavigate} from 'react-router-dom';
import axios from 'axios';

function failedMessage(){
  return (
    <div className='SuccessTransfer'>

      <div className='SuccessDesc'>
        Oopss.. Something's wrong
      </div>
      <div className='SuccessTTitle'>
        Please Try Again Later.. Check Your Connection
      </div>
      {/* <div className='SuccessTSubTitle'>
        You will redirected to <i>Halaman Utama</i> in <b className='secondCount'>10</b> seconds
      </div> */}
    </div>
  )
}

function successMessage(PaidBy,RefID,AmountPaid,TransferDate,billName){
  return (
    <div className='SuccessTransfer'>
      <div className='ContainerSuccessT'>
        <div className="LogoImage TransferImage"/>
        <div className="TransaContainer">
          <table className='TransacTable'>
            <tr>
              <td>Paid By </td>
              <td className='PaidBy'> {PaidBy} </td>
            </tr>
            <tr>
              <td>Transaction ID</td>
              <td className='RefID'> {RefID} </td>
            </tr>
            <tr>
              <td>Amount Paid</td>
              <td className='AmountPaid'> {AmountPaid} </td>
            </tr> 
            <tr>
              <td>Transaction Date</td>
              <td className='TransferDate'> {TransferDate} </td>
            </tr>
          </table>
        </div>
      </div>
      <div className='SuccessDesc'>
        Congrats, <b className='billName'> {billName} </b> you have been officially registered as Pertubuhan Lima Generasi Malaysia 
      </div>
      <br/>
      <div className='SuccessTTitle'>
        Receipt Transaction has been sent to your email
      </div>
      <div className='SuccessTSubTitle'>
        You will be redirected to <i>Halaman Utama</i> in <b className='secondCount'>10</b> seconds
      </div>
    </div>
  )
}

function Form() {
    let navigate = useNavigate();
    var dataCust = new FormData();

    const loadingCont = (e) => {
      return (
        <div className='EmailSwal'>
          <div class='emailDesc'>
            <span className="payContent">
              Butir Pembayaran anda sedang diproses, Sila Tunggu Sebentar <br/><br/>
              Proses ini mengambil masa dalam 10 saat.<br/>
              Bergantung kepada Kelajuan Internet
            </span> <br/><br/>
          </div>
        </div>
      )
    }

      React.useEffect(()=>{
        if(window.location.href.indexOf("?") > -1){ 
          var countDown = 10;
          let timerInterval;
          var CustICnum = parseInt(window.location.href.split("?")[1].split("&")[0].split("=")[1]);
          var BillCode = window.location.href.split("?")[1].split("&")[2].split("=")[1];
          var TransacID = window.location.href.split("?")[1].split("&")[5].split("=")[1];
          dataCust.append("CustICNum",CustICnum)
          dataCust.append("TransacID",TransacID)
          dataCust.append("BillCode",BillCode)
          dataCust.append("ToyyibPayPaymentURL",process.env.REACT_APP_PAYMENT_URL)

          const swalEmail = withReactContent(Swal).fire({
              position: "center",
              html : loadingCont(),
              showConfirmButton: false,
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              didOpen: () => {
                Swal.showLoading();
              },
              customClass: {
                  container:"swalSendEmail",
                  popup:"swalPopupEmail",
                  title:"swalTitleForm"
              },
              timerProgressBar: true
          })
          $('body').removeClass('swal2-height-auto')

          axios.post(process.env.REACT_APP_URL_DATABASE + '/plgsystem/Customer/getBill.php',dataCust).then((payment) =>{
              if(payment.status == 200){
                if(payment.data.status == 200){
                  swalEmail.close()
                  withReactContent(Swal).fire({
                    position: "center",
                    html: successMessage(
                      payment.data.billTo,
                      payment.data.billpaymentInvoiceNo,
                      "RM 180.00",
                      payment.data.billPaymentDate,
                      payment.data.billTo,
                    ),
                    didOpen : () => {
                      timerInterval = setInterval(function(){
                        countDown--;
                        $('.secondCount').html(countDown)
                      },1000)
                    },
                    didClose: () => {
                      clearInterval(timerInterval);
                    },
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    customClass: {
                        container:"swalContainerTransfer",
                        popup:"swalSuccessTransfer",
                        title:"swalTitleTransfer"
                    },
                    timerProgressBar: true
                    ,timer:10000
                })
                .then(res => {
                  if(res.isDismissed){
                    setTimeout(function(){
                      navigate('/')
                    },10000)
                  }
                })
                  $('body').removeClass('swal2-height-auto')
                } else {
                  swalEmail.close()
                  withReactContent(Swal).fire({
                    position: "center",
                    icon:"error",
                    html: failedMessage(),
                    didOpen : () => {
                      timerInterval = setInterval(function(){
                        countDown--;
                        $('.secondCount').html(countDown)
                      },1000)
                    },
                    didClose: () => {
                      clearInterval(timerInterval);
                    },
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    customClass: {
                        container:"swalContainerTransfer",
                        popup:"swalSuccessTransfer",
                        title:"swalTitleTransfer"
                    },
                    timerProgressBar: true
                    // ,timer:10000
                })
                // .then(res => {
                //   if(res.isDismissed){
                //     setTimeout(function(){
                //       navigate('/')
                //     },10000)
                //   }
                // })
                $('body').removeClass('swal2-height-auto')
                }
              }
          })
        }
      })

    return(
        <div className='SwalHolder'></div>
    );
};
  
  export default Form;
  
