import './ListApplicant.css';
import '../main.css';
import $ from 'jquery';
import { 
  useReactTable, 
  getCoreRowModel, 
  getFilteredRowModel,
  flexRender, 
  getPaginationRowModel } from '@tanstack/react-table';
import * as React from 'react';
import { 
  BsXLg,
  BsEye,
  BsFillTrashFill,
  BsCreditCard2FrontFill,
  BsPersonFillGear,
  BsFillPersonBadgeFill,
  BsThreads,
  BsFillPinMapFill ,
  BsPinMap,
  BsFillTelephoneFill,
  BsFillSuitcaseLgFill,
  BsFillHospitalFill ,
  BsPeopleFill,
  BsPersonFill,
  BsDoorOpenFill,
  BsSearch,
  BsFillPersonLinesFill,
  BsPersonPlusFill,
  BsFillPencilFill,
  BsFillGearFill,
  BsCheckLg,
  BsFillPostcardFill,
  BsCheck,
  BsTrashFill,
  BsExclamationCircleFill,
  BsEnvelopeFill,
  BsFillPersonFill,
  BsLockFill,
  BsFillFunnelFill,
  BsXSquareFill
} from "react-icons/bs";
import { useNavigate,Link} from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from 'axios';
import { getSHA256Hash } from "boring-webcrypto-sha256";

function ListApplicant() {
  let navigate = useNavigate();
  const [filter,setFilter] = React.useState('');
  const [dataCustomer,setDataCustomer] = React.useState({});
  const [filterApplicant, setFilterApplicant] = React.useState("");
  const [filterState, setFilterState] = React.useState("");
  const [checkUpdate,setCheckUpdate] = React.useState(false);
  const [currentIC, SetCurrentIC] = React.useState("");
  var swalAdd;
  var dataAdmin = new FormData();

  React.useEffect(() => {
    searchData()

    $('input').keypress(function(e){
        var txt = String.fromCharCode(e.which);
        if (!txt.match(/[A-Za-z0-9&./']/)) {
            return false;

            if (e.which !== 32) {
                return false;
            }
        }
    })

    // Assuming your <td> has a class name "editable-cell"
    $('.ApplicantDataTable').children().children('td:nth-child(even)').on('keydown', function(e) {
      if (e.which === 13 || (e.which === 10 && e.shiftKey)) {
          e.preventDefault(); // Prevent line breaks
          $(this).append(' '); // Add a space instead
      }
    });

    // setInterval(function(){
    //   searchData()
    // },15000)
  },[])

  const addApplicant = () => {
    return (
      <div className="">
        <div className="ApplicantController" onClick={() => swalAdd.close()}>
          <BsXLg/>
        </div>
        <div className="TitleForm">
          Maklumat Peribadi
        </div>
        <div className="TotalStep">
        <div class="progress" data-click="1">
          <div class="progress-bar"></div>
          <div class="step active">1</div>
          <div class="step">2</div>
          <div class="step">3</div>
          <div class="step">4</div>
          <div class="step">5</div>
        </div>
        </div>
        <div className='FormContainer'>
                  <div className="ImageContainer">
                          <div className="SubmitImage CUSTPIC"/>
                          <div className="ImageFile IconHolder">
                          <input type="file" className="selectedFile" onChange={(e) => readURL(e.target)}/>
                          <input type="button" className='FilePict' value="Pilih Kad Pengenalan" onClick={() => $('.selectedFile').click()} />
                          </div>
                      </div>
  
                  <div className="CredentialContainer">
                      <div className="IconHolder">
                          <div className="IconContainerTab">
                              <BsFillPersonFill />
                          </div>
                          <input type="text" className='FullName' id="CUSTNAME" placeholder='Nama Penuh' required/>
                      </div>
                      <div className="IconHolder">
                          <div className="IconContainerTab">
                              <BsCreditCard2FrontFill/>
                          </div>
                          <input type="number" className='ICNumber' id="CUSTICNUM" placeholder='No. Kad Pengenalan' required/>
                      </div>
                      <div className="IconHolder">
                          <div className="IconContainerTab">
                              <BsPersonFillGear/>
                          </div>
                          <input type="text" className='BirthDate' id="CUSTBIRTHDATE" onFocus={(e) => e.target.type='date'} onBlur={(e) => e.target.type='text'} placeholder='Tarikh Lahir' required/>
                      </div>
                      <div className="IconHolder">
                          <div className="IconContainerTab">
                              <BsFillPersonBadgeFill/>
                          </div>
                          <input type="text" className='LocationIC' id="CUSTICADDRESS" placeholder='Alamat (Kad Pengenalan)' required/>
                      </div>
                  </div>
              </div>
  
              
              <div className="ContactInfo">
              <div className="IconHolder">
                      <div className="IconContainerTab">
                          <BsThreads />
                      </div>
                          <input type="email" className='Email' id="CUSTEMAIL" placeholder='Email' required/>
                  </div>
                  
                  <div className="IconHolder">
                      <div className="IconContainerTab">
                          <BsFillTelephoneFill  />
                      </div>
                          <input type="number" className='PersonalNumber' id="CUSTPERSONALNUM" placeholder='No. Phone Peribadi' required/>
                  </div>
                  
                  <div className="IconHolder">
                      <div className="IconContainerTab">
                          <BsExclamationCircleFill   />
                      </div>
                          <input type="number" className='EmergencyNumber' id="CUSTEMERGENCYNUM" placeholder='No. Phone Kecemasan' required/>
                  </div>
                  
                  <div className="IconHolder">
                      <div className="IconContainerTab">
                          <BsFillPinMapFill />
                      </div>
                      <input type="text" className='Address' id="CUSTCURRENTADDRESS" placeholder='Alamat Semasa' required/>
                  </div>
              </div>
  
              <div className="EmploymentContainer">
                  <div className="IconHolder">
                      <div className="IconContainerTab">
                          <BsFillHospitalFill/>
                      </div>
                      <input type="text" className='EmployName' id="EMPLOYNAME" placeholder='Nama Majikan' required/>
                  </div>
  
                  <div className="IconHolder">
                      <div className="IconContainerTab">
                          <BsFillSuitcaseLgFill/>
                      </div>
                      <input type="text" className='EmployLvl' id="EMPLOYPOSITION" placeholder='Pangkat & Pekerjaan' required/>
                  </div>
                  
                  <div className="IconHolder">
                      <div className="IconContainerTab">
                          <BsFillPinMapFill/>
                      </div>
                      <input type="text" className='LocationEmploy' id="EMPLOYADDRESS" placeholder='Alamat Majikan' required/>
                  </div>
  
                  <div className="IconHolder">
                      <div className="IconContainerTab">
                          <BsPeopleFill/>
                      </div>
                      <input type="text" className='SectIndustry' id="EMPLOYINDUSTRY" placeholder='Sektor Industri' required/>
                  </div>
              </div>
  
              <div className='SuggestionSect'>
                  <div className="IconHolder">
                      <div className="IconContainerTab">
                          <BsPersonFill />
                      </div>
                          <input type="text" className='SuggestedBy' id="SUGGESTAYAH" placeholder='Pencadang YDH Ayah' required/>
                  </div>
                  <div className="IconHolder">
                      <div className="IconContainerTab">
                          <BsPersonFill />
                      </div>
                          <input type="text" className='MajistretSuggest' id="MAJISTRETNAME" placeholder='Majlis Pencadang' required/>
                  </div>
                  <div className="IconHolder">
                      <div className="IconContainerTab">
                          <BsFillHospitalFill />
                      </div>
                          <input type="text" className='JawatanMajlis' id="MAJISTRETPOSTION" placeholder='Jawatan Dalam Majlis' required/>
                  </div>
                  
                  <div className="IconHolder">
                      <div className="IconContainerTab">
                          <BsFillTelephoneFill  />
                      </div>
                          <input type="number" className='MajlisNumber' id="MAJISTRETPHONE" placeholder='No. Phone Pencadang' required/>
                  </div>
              </div>
  
              <div className="RegisterCont">
              <div className="IconHolder">
                      <div className="IconContainerTab">
                          <BsPinMap/>
                      </div>
                      <select className="ParliamentState" id="RESSTATE">
                          <option selected value="">Pilih Pendaftaran Negeri PLG</option>
                          <option value="Selangor">Selangor</option>
                          <option value="Kuala Lumpur">Kuala Lumpur</option>
                          <option value="Putrajaya">Putrajaya</option>
                          <option value="Pahang">Pahang</option>
                          <option value="Perak">Perak</option>
                          <option value="Terengganu">Terengganu</option>
                          <option value="Sabah">Sabah</option>
                          <option value="Sarawak">Sarawak</option>
                          <option value="Kedah">Kedah</option>
                          <option value="Kelantan">Kelantan</option>
                          <option value="Perlis">Perlis</option>
                          <option value="Negeri Sembilan">Negeri Sembilan</option>
                          <option value="Pulau Pinang">Pulau Pinang</option>
                          <option value="Melaka">Melaka</option>
                          <option value="Johor">Johor</option>
                      </select>
                  </div>
              <div className="IconHolder">
                      <div className="IconContainerTab">
                          <BsPersonFill />
                      </div>
                          <input type="text" className='JawatanPLG' id="RESPOSITION" placeholder='Jawatan Dalam PLG' required/>
                  </div>
                  
                  <div className="IconHolder">
                      <div className="IconContainerTab">
                          <BsFillHospitalFill />
                      </div>
                          <input type="text" className='ParliamentName' id="RESPARLIMEN" placeholder='PLG Parlimen' required/>
                  </div>
  
                  <div className="IconHolder">
                      <div className="IconContainerTab">
                          <BsFillTelephoneFill  />
                      </div>
                          <input type="number" className='PengerusiNumber' id="RESPHONE" placeholder='No. Phone Pengerusi' required/>
                  </div>
              </div>
  
              <div className='buttonSubmitCont AdminButtonControl'>
                      <button className="BackContactInfo" onClick={() => showBasicCred()}>Back</button>
                      <button className="BackAddress" onClick={() => showContactInfo()}>Back</button>
                      <button className="BackRegister" onClick={() => showEmploymentContainer()}>Back</button>
                      <button className="BackSuggest" onClick={() => showSuggest()}>Back</button>
  
                      <button className="NextBasicCred" onClick={() => showContactInfo()}>Next</button>
                      <button className='NextContactInfo' onClick={() => showEmploymentContainer()}>Next</button>
                      <button className='NextRegister' onClick={() => showSuggest()}>Next</button>
                      <button className='NextSuggest' onClick={() => showRegister()}>Next</button>
                      <button className='SubmitAddress' onClick={() => {showSwal()}} >Hantar Permohonan</button>
              </div>
      </div>
    )
  }

  const showBasicCred = (e) => {
        $(e).parent().children('li').map((i,value) => {
            $(value).removeClass('SelectedTab')
        })

        $('.progress').children(".step").removeClass('active')
        $('.TitleForm').html("Maklumat Peribadi")

        $('.progress-bar').css('width','0%')
        $('.progress').children(".step").map((index,value) => {
          if(index <= 0){
            $(value).addClass('active')
          }
        })

        $(e).addClass('SelectedTab')
        $('.FormContainer').show()
        $('.SuggestionSect').hide()
        $('.ContactInfo').hide()
        $('.EmploymentContainer').hide()
        $('.RegisterCont').hide()

        $('.BackAddress').hide()
        $('.BackSuggest').hide()
        $('.BackContactInfo').hide()
        $('.BackRegister').hide()

        $('.NextRegister').hide()
        $('.NextBasicCred').show()
        $('.NextContactInfo').hide()
        $('.NextSuggest').hide()
        $('.SubmitAddress').hide()
    }

    const showContactInfo = (e) => {
        $(e).parent().children('li').map((i,value) => {
            $(value).removeClass('SelectedTab')
        })
        $('.progress').children(".step").removeClass('active')

        $('.progress-bar').css('width','25%')
        $('.progress').children(".step").map((index,value) => {
          if(index <= 1){
            $(value).addClass('active')
          }
        })

        $('.TitleForm').html("Maklumat Perhubungan")

        $(e).addClass('SelectedTab')
        $('.FormContainer').hide()
        $('.ContactInfo').show().css('display', 'flex');
        $('.SuggestionSect').hide()
        $('.EmploymentContainer').hide()
        $('.RegisterCont').hide()

        $('.BackSuggest').hide()
        $('.BackAddress').hide()
        $('.BackContactInfo').show()
        $('.BackRegister').hide()

        $('.NextRegister').hide()
        $('.NextSuggest').hide()
        $('.NextBasicCred').hide()
        $('.NextContactInfo').show()
        $('.SubmitAddress').hide()
    }

    const showRegister = (e) => {
        $(e).parent().children('li').map((i,value) => {
            $(value).removeClass('SelectedTab')
        })
        $('.progress').children(".step").removeClass('active')

        $('.progress-bar').css('width','100%')
        $('.progress').children(".step").map((index,value) => {
          if(index <= 4){
            $(value).addClass('active')
          }
        })
        
        $('.TitleForm').html("Maklumat Parlimen")

        getSHA256Hash('NormalAdmin100').then(e => {
          if(sessionStorage.getItem("AdminPrivi") == e){
            $($('.RegisterCont').children('.IconHolder')[0]).hide()
            $('.ParliamentState').val(sessionStorage.getItem("AdminState"))
          }
        })

        $(e).addClass('SelectedTab')
        $('.FormContainer').hide()
        $('.SuggestionSect').hide()
        $('.ContactInfo').hide()
        $('.EmploymentContainer').hide();
        $('.RegisterCont').show().css('display', 'flex');

        $('.BackSuggest').show()
        $('.BackAddress').hide()
        $('.BackContactInfo').hide()
        $('.BackRegister').hide()

        $('.NextRegister').hide()
        $('.NextBasicCred').hide()
        $('.NextSuggest').hide()
        $('.NextContactInfo').hide()
        $('.SubmitAddress').show()
    }

    const showSuggest = (e) => {
        $(e).parent().children('li').map((i,value) => {
            $(value).removeClass('SelectedTab')
        })
        $('.progress').children(".step").removeClass('active')

        $('.progress-bar').css('width','75%')
        $('.progress').children(".step").map((index,value) => {
          if(index <= 3){
            $(value).addClass('active')
          }
        })
        
        $('.TitleForm').html("Maklumat Pencadang")
        
        $(e).addClass('SelectedTab')
        $('.FormContainer').hide()
        $('.ContactInfo').hide()
        $('.EmploymentContainer').hide();
        $('.RegisterCont').hide();
        $('.SuggestionSect').show().css('display', 'flex');

        $('.BackSuggest').hide()
        $('.BackAddress').hide()
        $('.BackContactInfo').hide()
        $('.BackRegister').show()

        $('.NextRegister').hide()
        $('.NextBasicCred').hide()
        $('.NextSuggest').show()
        $('.NextContactInfo').hide()
        $('.SubmitAddress').hide()
    }

    const showEmploymentContainer = (e) => {
        $(e).parent().children('li').map((i,value) => {
            $(value).removeClass('SelectedTab')
        })
        $('.progress').children(".step").removeClass('active')

        $('.progress-bar').css('width','50%')
        $('.progress').children(".step").map((index,value) => {
          if(index <= 2){
            $(value).addClass('active')
          }
        })
        
        $('.TitleForm').html("Maklumat Pekerjaan")

        $(e).addClass('SelectedTab')
        $('.FormContainer').hide()
        $('.ContactInfo').hide()
        $('.EmploymentContainer').show().css('display', 'flex');
        $('.RegisterCont').hide()
        $('.SuggestionSect').hide()

        $('.BackSuggest').hide()
        $('.BackAddress').show()
        $('.BackContactInfo').hide()
        $('.BackRegister').hide()

        $('.NextRegister').show()
        $('.NextBasicCred').hide()
        $('.NextContactInfo').hide()
        $('.NextSuggest').hide()
        $('.SubmitAddress').hide()
    }

  const errorSwal = () => {
    withReactContent(Swal).fire({
        position: () => {
            if(window.innerWidth > 600) {
                return "top-end";
            } else {
                return "center";
            }
        },
        html : "<div class='errorMessage InvalidPict'>Hanya Gambar .png, .jpeg, .jpg sahaja yang diterima. Sila Tukar Gambar</div>",
        showConfirmButton: false,
        customClass: {
            container:"swalContainerForm",
            popup:"swalPopupForm",
            title:"swalTitleForm"
        },
        timerProgressBar: true
        ,timer:3000
    })
    
    $('body').removeClass('swal2-height-auto')
  }

  const checkScreenSize = () => {
      if(window.innerWidth > 600){
          return "top-end"
      } else {
          return "center"
      }
  }

  const showSwal = () => {
    var emptyValue = "";
    var dataCust = new FormData();
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    $('.IconHolder').children(":odd").map((i,value) => {
        if(value.value == "" && value.className != 'OfficeNumber') {
            if(value.placeholder == undefined){
                if(value.id == "RESSTATE"){
                    emptyValue += "* Pendaftaran Negeri PLG \n "
                } else {
                    emptyValue += "* " + value.id + "\n "
                }
            } else {
                emptyValue += "* " + value.placeholder + "\n "
            }
        } else {
          dataCust.append(value.className,value.value)
        }
    })

    if(!($('.selectedFile')[0].files.length > 0)){
        emptyValue += "* Gambar Peribadi\n "
    } else {
        dataCust.append("FilePict",$('.selectedFile')[0].files[0])
    }
    
    if(emptyValue == "") {
        if(emailPattern.test($('.Email').val())){
          console.log([...dataCust])
          axios.post(process.env.REACT_APP_URL_DATABASE + '/plgsystem/Customer/addData.php',dataCust,{withCredentials:true}).then((resCust) =>{
            if(resCust.status == 200){
                if(resCust.data.status == undefined){
                    withReactContent(Swal).fire({
                            position: checkScreenSize(),
                            html : reloadPopup(),
                            showConfirmButton: false,
                            customClass: {
                                container:"swalContainerForm",
                                popup:"successMessage",
                                title:"swalTitleForm"
                            },
                            timerProgressBar: true
                            ,timer: 3000
                        })

                        searchData()
                        $('body').removeClass('swal2-height-auto')
                } else {
                    withReactContent(Swal).fire({
                            position: checkScreenSize(),
                            html : validateDuplicate(),
                            showConfirmButton: false,
                            customClass: {
                                container:"swalContainerForm",
                                popup:"swalPopupForm",
                                title:"swalTitleForm"
                            },
                            timerProgressBar: true
                            ,timer: 3000
                        })
    
                        $('body').removeClass('swal2-height-auto')
                }
            }
        })
        } else {
          alert("Sila pastikan format email adalah sah!")
        }
    } else {
        alert("Sila pastikan maklumat dibawah lengkap : \n" + emptyValue)
    }
  }

  const searchData = () => {
    if(sessionStorage.getItem('tokenAuth') != null){
      var auth = new FormData();
      auth.append('jwt_auth',sessionStorage.getItem('tokenAuth'))
      auth.append('AuthKey',process.env.REACT_APP_AUTH_KEY) 
      axios.post(process.env.REACT_APP_URL_DATABASE + 'plgsystem/Admin/viewData.php',auth).then(resAdmin => {
        if(resAdmin.status == 200){
          sessionStorage.setItem("AdminState",resAdmin.data.state)
          sessionStorage.setItem("ADMINID",resAdmin.data.id)
          sessionStorage.setItem("AdminPrivi",resAdmin.data.privi)
          dataAdmin.append("AdminState",resAdmin.data.state)
          dataAdmin.append("ADMINID",resAdmin.data.id)
          dataAdmin.append('jwt_auth',sessionStorage.getItem('tokenAuth'))
          dataAdmin.append('AuthKey',process.env.REACT_APP_AUTH_KEY) 
          
          getSHA256Hash('SuperAdmin200').then((privToken)=> {
            if(resAdmin.data.privi == privToken) {
              axios.post(process.env.REACT_APP_URL_DATABASE + 'plgsystem/Customer/viewData.php',dataAdmin).then(resCust => {
                if(resCust.status == 200){
                  setDataCustomer(resCust.data)
                } 
              })
            } else {
              axios.post(process.env.REACT_APP_URL_DATABASE + 'plgsystem/Customer/viewDataCust.php',dataAdmin).then(resCust => {
                if(resCust.status == 200){
                  setDataCustomer(resCust.data)
                  $('.filterState').remove()
                  $('.ListAdmin').remove()
                } 
              })
            }
          })
        }
      })
    } else {
      navigate('/LoginPage')
    }
  }

  const updatedInfo = () => {
    var updatedData = new FormData();
    $('.ApplicantDataTable').children().children('td:nth-child(even)').map((index,value) => {
      updatedData.append($(value).attr('class'),$(value).html())
    })
    
    axios.post(process.env.REACT_APP_URL_DATABASE + 'plgsystem/Customer/editData.php',updatedData).then((res) => {
      if(res.status == 200){
        if(res.data.status == undefined){
            withReactContent(Swal).fire({
              position: "top-start",
              html : iconContainer("Update"),
              showConfirmButton: false,
              customClass: {
                  container:"swalContainerForm",
                  popup:"swalPopupForm successMessage",
                  title:"swalTitleForm"
              },
              timerProgressBar: true
              ,timer:3000
          })
          $('body').removeClass('swal2-height-auto')
          searchData()
        } else if(res.data.status == 300){
              withReactContent(Swal).fire({
                position: "top-start",
                html : validateDuplicate("Edit"),
                showConfirmButton: false,
                customClass: {
                    container:"swalContainerForm",
                    popup:"swalPopupForm errorMessage editAdminApplicant",
                    title:"swalTitleForm"
                },
                timerProgressBar: true
                ,timer:3000
            })
            $('body').removeClass('swal2-height-auto')
            searchData()
        }
      }
    })
  }

  const generateInfo = (props,id) => {
    $('.ApplicantContainer').css('width',"50%");
    for(var i = 0;i <= Object.keys(props.row.original).length - 1;i++){
      if(isNaN(parseInt(Object.keys(props.row.original)[i]))){
        $('.' + Object.keys(props.row.original)[i]).html(Object.values(props.row.original)[i])
      }
    }

    SetCurrentIC(props.row.original.CUSTICNUM)
    $('.ICNumber').html(props.row.original.CUSTICNUM)
    $('.ApplicantImage').attr('src',process.env.REACT_APP_URL_DATABASE + props.row.original.CUSTPIC)
  }

  const sendEmail = (props,element) => {
    var sendDataCust = new FormData();
    let timerInterval;
    sendDataCust.append("CustID",props.row.original.CUSTID)
    sendDataCust.append("ToyyibPayPaymentUrl",process.env.REACT_APP_PAYMENT_URL)
    sendDataCust.append("CustEmail",props.row.original.CUSTEMAIL)
    sendDataCust.append("CustRegister",props.row.original.CUSTREGISTER)
    sendDataCust.append("CustName",props.row.original.CUSTNAME)
    sendDataCust.append("MainURL",process.env.REACT_APP_MAIN_URL)

    const swalEmail = withReactContent(Swal).fire({
      position: "center",
      html : emailCont(props.row.original.CUSTEMAIL),
      showConfirmButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        var count = 1;
        Swal.showLoading();
        timerInterval = setInterval(function(){
          count++;
          $('.countTimer').html(count)
        },1000)
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      customClass: {
          container:"swalSendEmail",
          popup:"swalPopupEmail",
          title:"swalTitleForm"
      },
      timerProgressBar: true
  })
  $('body').removeClass('swal2-height-auto')

    axios.post(process.env.REACT_APP_URL_DATABASE + 'plgsystem/Customer/sendBill.php',sendDataCust).then(resCust => {
      if(resCust.status == 200){
        swalEmail.close()
        withReactContent(Swal).fire({
          position: "top-start",
          html : iconContainer("SendEmail"),
          showConfirmButton: false,
          customClass: {
              container:"swalContainerForm",
              popup:"swalPopupForm successMessage",
              title:"swalTitleForm"
          },
          timerProgressBar: true,
          timer:3000
      })
      $('body').removeClass('swal2-height-auto')
      }
    })
  }

  const dumpData = (props,element) => {
    let timerInterval;
    var count = 1;
    dataAdmin.append("CustID",props.row.original.CUSTID)
    dataAdmin.append("ToyyibPayPaymentUrl",process.env.REACT_APP_PAYMENT_URL)
    dataAdmin.append("CustEmail",props.row.original.CUSTEMAIL)
    dataAdmin.append("CustName",props.row.original.CUSTNAME)
    dataAdmin.append("MainURL",process.env.REACT_APP_MAIN_URL)
    dataAdmin.append("CustRegister",props.row.original.CUSTREGISTER)

    const swalEmail = withReactContent(Swal).fire({
        position: "center",
        html : emailCont(props.row.original.CUSTEMAIL),
        showConfirmButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
        timerInterval = setInterval(function(){
          count++;
          $('.countTimer').html(count)
        },1000)
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
        customClass: {
            container:"swalSendEmail",
            popup:"swalPopupEmail",
            title:"swalTitleForm"
        },
        timerProgressBar: true
    })
    $('body').removeClass('swal2-height-auto')
    
    axios.post(process.env.REACT_APP_URL_DATABASE + 'plgsystem/Customer/deleteData.php',dataAdmin).then(resCust => {
      if(resCust.status == 200){
        swalEmail.close()
        withReactContent(Swal).fire({
          position: "top-start",
          html : iconContainer("Delete"),
          showConfirmButton: false,
          customClass: {
              container:"swalContainerForm",
              popup:"swalPopupForm",
              title:"swalTitleForm"
          },
          timerProgressBar: true,
          timer:3000
      })
      $('body').removeClass('swal2-height-auto')
        searchData()
      }
    })
  }

  const readURL = (input) => {
      if (input.files && input.files[0]) {
          var reader = new FileReader();
          var validFile =  ["jpg", "jpeg", "png"];
          var checkFile = 0;

          for(var i = 0;i < validFile.length;i++){
              if(input.files[0].type.split("/")[1] === validFile[i]){
                  checkFile += 1;
              }
          }

          if(checkFile > 0){
              reader.onload = function (e) {
                  $('.SubmitImage').css('background-image',"url("+ e.target.result +")");
              }
      
              reader.readAsDataURL(input.files[0]);
          } else {
              errorSwal()
          }
      }
  }

  const updateApprove = (props,element) => {
    dataAdmin.append("CustID",props.row.original.CUSTID)
    dataAdmin.append("ADMINID",sessionStorage.getItem("ADMINID"))
    axios.post(process.env.REACT_APP_URL_DATABASE + 'plgsystem/Admin/updateApproval.php',dataAdmin).then(resCust => {
      if(resCust.status == 200){
        withReactContent(Swal).fire({
          position: "top-start",
          html : iconContainer("UpdateApprove"),
          showConfirmButton: false,
          customClass: {
              container:"swalContainerForm",
              popup:"swalPopupForm successMessage",
              title:"swalTitleForm"
          },
          timerProgressBar: true,
          timer:3000
      })
      $('body').removeClass('swal2-height-auto')
        searchData()
      }
    })
  }

  const reupdateApprove = (props,element) => {
    dataAdmin.append("CustID",props.row.original.CUSTID)
    dataAdmin.append("ADMINID",sessionStorage.getItem("ADMINID"))
    axios.post(process.env.REACT_APP_URL_DATABASE + 'plgsystem/Admin/reupdateApproval.php',dataAdmin).then(resCust => {
      if(resCust.status == 200){
        withReactContent(Swal).fire({
          position: "top-start",
          html : iconContainer("ReupdateApprove"),
          showConfirmButton: false,
          customClass: {
              container:"swalContainerForm",
              popup:"swalPopupForm successMessage",
              title:"swalTitleForm"
          },
          timerProgressBar: true,
          timer:3000
      })
      $('body').removeClass('swal2-height-auto')
        searchData()
      }
    })
  }

  const columns = [
    {
      accessor : 'ApprovalID',
      header : "ID",
      cell : (props) => <p>{props.row.original.APPROVALID}</p>
    },
    {
      accessor : 'FullName',
      header : "Nama Penuh",
      cell : (props) => <p>{props.row.original.CUSTNAME}</p>
    },
    {
      accessor : 'ICNumber',
      header : "No. Kad Pengenalan",
      cell : (props) => <p>{props.row.original.CUSTICNUM}</p>
    },
    {
      accessor : 'BillCode',
      header : "Kod Bill",
      cell : (props) => <p>{props.row.original.BILLCODE}</p>
    },
    {
      accessor : 'Stats',
      header : "Status",
      cell : (props) => <center className={"ListApplicant_"+ props.row.original.APPROVALSTATUS}>{props.row.original.APPROVALSTATUS}</center>
    },
    {
      accessor : 'Action',
      header : "Tindakan  ",
      cell : (props) => (
        <div className='ViewApplicant'>
          <BsEye className='ViewData' id={props.row.id} onClick={(e) => {
            $('.ApplicantDataTable').children().children('td:nth-child(even)').attr("contenteditable",false)
            $('.ApplicantDataTable').children('tr:nth-child(even)').children('td:nth-child(even)').css('background-color','#d4d4d4')
            $('.ApplicantDataTable').children('tr:nth-child(odd)').children('td:nth-child(even)').css('background-color','white')
            $('.ApplicantDataTable').children().children('td:nth-child(even)').css('padding','.4em 0')
            $('.ICNumber').html(currentIC)
            $('.FinishEdit').hide()
            $('.EditInfo').show()
            generateInfo(props,e.target.id)
          }}/>
          <BsFillTrashFill className='DumpData' id={props.row.id} onClick={(e) => dumpData(props,e.target.id)} />
          <BsEnvelopeFill className='SendEmail' id={props.row.id} onClick={(e) => sendEmail(props,e.target.id)}/>
          {
            props.row.original.APPROVALSTATUS === "Approved" ? <BsXLg className='PendData' id={props.row.id} onClick={(e) => reupdateApprove(props,e.target.id)}/> : <BsCheckLg className='VerifyData' id={props.row.id} onClick={(e) => updateApprove(props,e.target.id)}/>
          }
        </div>
      )      
    }
  ];

  const filteredData = React.useMemo(() => {
    if (!filter && !filterApplicant && !filterState) {
      return dataCustomer;
    } else {
      return dataCustomer.filter(row => {
        // Apply text filter to all values
        const textMatch = Object.values(row).some(value => {
          return String(value).toLowerCase().includes(filter.toLowerCase());
        });
        
        // Apply select filter to a specific field, e.g., 'Subscription'
        const selectMatch = filterApplicant === "" || row.APPROVALSTATUS === filterApplicant;
        const selectState = !filterState|| row.RESSTATE === filterState;
        return textMatch && selectMatch && selectState;
      });
    }
  }, [filter, filterApplicant,filterState, dataCustomer]);

  const [pagination, setPagination] = React.useState({
    pageIndex: 0, //initial page index
    pageSize:15, //default page size
  });
  
  const tableApps = useReactTable({
    data : filteredData,
    columns,
    filter,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    getFilteredRowModel: getFilteredRowModel(),
    state : {
      pagination,
      globalFilter: filter
    }
  });

  const showApplicant = () => {
    swalAdd = withReactContent(Swal).fire({
      showCancelButton: false,
      showConfirmButton:false,
      customClass:{
        confirmButton:"confirmEditAdmins"
      },
      html: addApplicant()
    })

    setTimeout(function(){
      $('.NextBasicCred').show()
    },50)
  }

  const editSwal = () => {
    var editCurrentAdmin = new FormData();
    withReactContent(Swal).fire({
      title: "Edit My Information",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Change My Credentials",
      cancelButtonText: "Cancel",
      customClass:{
        actions : "editSwalAdmins",
        confirmButton:"confirmEditAdmins"
      },
      html: editCurrentElement()
    }).then((result) => {
      if(result.isConfirmed){
      var newAdminIC = "";
      editCurrentAdmin.append('ADMINID',sessionStorage.getItem("ADMINID"))
      editCurrentAdmin.append('AdminPrivi',sessionStorage.getItem("AdminPrivi"))
      editCurrentAdmin.append("AdminState",sessionStorage.getItem("AdminState"))
      $('.CPCont').children(":odd").map((i,value) =>{
        if($(value).attr('id') != undefined){
          if($(value).attr('id') == "ICNum"){
            newAdminIC = $(value).val()
          }

          editCurrentAdmin.append($(value).attr('id'),$(value).val())
        }
      })

      axios.post(process.env.REACT_APP_URL_DATABASE + 'plgsystem/Admin/updateCurrentAdmin.php',editCurrentAdmin).then(resAdmin => {
        if(resAdmin.status == 200){
          sessionStorage.setItem('tokenAuth',resAdmin.data.jwt_auth)
          withReactContent(Swal).fire({
                position: "top-start",
                html : iconContainer("Update"),
                showConfirmButton: false,
                customClass: {
                    container:"swalContainerForm",
                    popup:"swalPopupForm successMessage",
                    title:"swalTitleForm"
                },
                timerProgressBar: true,
                timer:3000
            })
            
            $('body').removeClass('swal2-height-auto')
        }
      })
      }
    })

    $('.confirmEditAdmins').prop('disabled',true)
    $('body').removeClass('swal2-height-auto')
  }

  const emailCont = (e) => {
    return (
      <div className='EmailSwal'>
        <div class='emailDesc'>
          <span className="emailcontent">
            Butir Pembayaran sedang dihantar kepada {e}, Sila Tunggu Sebentar <br/>
            Penghantaran email mengambil masa dalam 10 - 15 saat.<br/>
            Bergantung kepada Kelajuan Internet
          </span> <br/><br/>
          <span className="explicitEmail">
            Jika proses ini terlalu lama, Sila <i>refresh</i> Halaman Admin <br/>
            Tempoh Penghantaran Semasa : &nbsp;
            <span className="countTimer">1</span>&nbsp;saat
          </span>
        </div>
      </div>
    )
  }

  return (
    <div className="LogContainer">
      <div className="NavigationCont">
      <div className="headCont">
        <div className='ContImage'>
          <div className="LogoImage"/>
          <div className="LogoTitle">Pertubuhan Lima <br/> Generasi Malaysia</div>
        </div>
        <ul className="ListMenu">
        <li className='Selected'><BsFillPersonLinesFill /> <div>List Of Applicant</div></li>
        <li className='ListAdmin'><Link to='/Admin/ListAdmin'><BsFillPersonFill /> <div>List Admin</div></Link></li>
        <li onClick={(e) => editSwal()}><BsFillGearFill/> <div>Change Password</div></li>
        </ul>
        </div>

        <div className="LogoutButton">
        {/* <div className="StorageContainer">
            <div className="UsedStorage">30.00GB</div>
            <progress value="32" max="100"/>
            <div className="AvailableStorage">200.00GB</div>
        </div> */}

          <Link to="/LoginPage" className='LogOutButtContainer'><button onClick={() => {
            sessionStorage.clear();
          }}><BsDoorOpenFill /> Logout</button></Link>
          <div className='Copyright'>Managed by © ClicTec Solution </div>
        </div>
      </div>

      <div className='ContentContainer'>
        <div className='TableCont'>
        <div className="ContainerTableSearch">
        <div className="OptionController">
          <div className='SearchFilter'>
            <div className='AddApplicant' onClick={() => showApplicant()}>
              <BsPersonPlusFill className='AddApplicantManual'/>
            </div>

            <div className="SearchContainer">
              <BsSearch className='SearchImage'/>
              <input type='text' placeholder="Cari Maklumat Pengguna" value={filter} onChange={e => setFilter(e.target.value)}/>
            </div>
          </div>

          <div className="SelectFilter">
          <div className="IconFilter">
              <BsFillFunnelFill/>
            </div>
            <select className="filterStats" onChange={(e) => setFilterApplicant(e.target.value)}>
              <option selected value="">Show All Status</option>
              <option value="Approved">Status : Approved</option>
              <option value="Pending">Status : Pending</option>
            </select>
            
            <select className="filterState" onChange={(e) => setFilterState(e.target.value)}>
              <option selected value="">Show All State</option>
              <option value="Selangor">State : Selangor</option>
              <option value="Kuala Lumpur">State : Kuala Lumpur</option>
              <option value="Putrajaya">State : Putrajaya</option>
              <option value="Pahang">State : Pahang</option>
              <option value="Perak">State : Perak</option>
              <option value="Terengganu">State : Terengganu</option>
              <option value="Sabah">State : Sabah</option>
              <option value="Sarawak">State : Sarawak</option>
              <option value="Kedah">State : Kedah</option>
              <option value="Kelantan">State : Kelantan</option>
              <option value="Perlis">State : Perlis</option>
              <option value="Negeri Sembilan">State : Negeri Sembilan</option>
              <option value="Pulau Pinang">State : Pulau Pinang</option>
              <option value="Melaka">State : Melaka</option>
              <option value="Johor">State : Johor</option>
            </select>
          </div>
          
        </div>
          <table className='TableApps'>
            {tableApps.getHeaderGroups().map(headerGroup => <tr key={headerGroup.id}>
              {headerGroup.headers.map((
                header => <th key={header.id}>
                  {header.column.columnDef.header}
                </th>
              ))}
            </tr>)}
            {tableApps.getRowCount() > 0 ? (
                tableApps.getRowModel().rows.map(row => (
                    <tr key={row.id}>
                        {row.getVisibleCells().map(cell => (
                            <td key={cell.id}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        ))}
                    </tr>
                ))
            ) : (
              <tr>
                <td colSpan='6' className="emptyTable">
                  <BsXSquareFill/><br/><br/>
                  Tiada data di dalam sistem <br/>
                  atau<br/>
                  Data yang anda cari tiada di dalam sistem
                </td>
              </tr>
            )}
          </table>
          </div>
          
          <div className='ButtonController'>
            <button
              onClick={() => tableApps.firstPage()}
              disabled={!tableApps.getCanPreviousPage()}
            >
              {'<<'}
            </button>
            <button
              onClick={() => tableApps.previousPage()}
              disabled={!tableApps.getCanPreviousPage()}
            >
              {'<'}
            </button>
            <button
              onClick={() => tableApps.nextPage()}
              disabled={!tableApps.getCanNextPage()}
            >
              {'>'}
            </button>
            <button
              onClick={() => tableApps.lastPage()}
              disabled={!tableApps.getCanNextPage()}
            >
              {'>>'}
            </button>

            <select
            value={tableApps.getState().pagination.pageSize}
            onChange={e => {
              tableApps.setPageSize(Number(e.target.value))
            }}
          >
            {[15,30,45,60,75].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          </div>
        </div>

        <div className="ApplicantContainer">
          <div className='OptionContainer'>
            <div className='ICNumber TitleWindow'/>
            <div className="ControlButtCont">
              <BsCheckLg className='FinishEdit' onClick={(e) => {
                $('.ApplicantDataTable').children().children('td:nth-child(even)').attr("contenteditable",false)
                $('.ApplicantDataTable').children('tr:nth-child(even)').children('td:nth-child(even)').css('background-color','#d4d4d4')
                $('.ApplicantDataTable').children('tr:nth-child(odd)').children('td:nth-child(even)').css('background-color','white')
                $('.ApplicantDataTable').children().children('td:nth-child(even)').css('padding','.4em 0')
                $('.ICNumber').html(currentIC)
                updatedInfo()
                $('.FinishEdit').hide()
                $('.EditInfo').show()
              }}/>
              <BsFillPencilFill className="EditInfo" onClick={(e) => {
                $('.ApplicantDataTable').children().children('td:nth-child(even)').attr("contenteditable",true)
                $('.ApplicantDataTable').children().children('td:nth-child(even)').css('background-color','white')
                $('.ApplicantDataTable').children().children('td:nth-child(even)').css('padding','.4em 1em')
                $('.ICNumber').html("Edit Mode Enabled")
                $('.EditInfo').hide()
                $('.FinishEdit').show()
              }} />
              <BsXLg onClick={() => {
                $('.ApplicantDataTable').children().children('td:nth-child(even)').attr("contenteditable",false)
                $('.ApplicantDataTable').children('tr:nth-child(even)').children('td:nth-child(even)').css('background-color','#d4d4d4')
                $('.ApplicantDataTable').children('tr:nth-child(odd)').children('td:nth-child(even)').css('background-color','white')
                $('.ApplicantDataTable').children().children('td:nth-child(even)').css('padding','.4em 0')
                $('.ApplicantContainer').css('width','0%')
                $('.FinishEdit').hide()
                $('.EditInfo').show()
              }}/>
            </div>
          </div>
          <img className="ApplicantImage"/>
          <div className='ApplicantBasicData'>
            <table className='ApplicantDataTable'>  
            <tr>
              <td className="tableHeader_ApplicantData" colSpan='2'>Maklumat Peribadi</td>
            </tr>
            <tr style={{display:"none"}}>
                <td>ID Applicant</td>
                <td className="CUSTID"></td>
            </tr>
            <tr >
                <td>Membership ID</td>
                <td className="APPROVALID"></td>
            </tr>
            <tr >
                <td>Nama Penuh</td>
                <td className="CUSTNAME"></td>
            </tr>
            <tr>
                <td>No. Kad Pengenalan</td>
                <td className="CUSTICNUM"></td>
            </tr>
            <tr>
                <td>Tarikh Lahir</td>
                <td className="CUSTBIRTHDATE"></td>
            </tr>
            <tr>
                <td>Alamat Kad Pengenalan</td>
                <td className="CUSTICADDRESS"></td>
            </tr>
            <tr>
                <td>Alamat Semasa</td>
                <td className="CUSTCURRENTADDRESS"></td>
            </tr>
            <tr>
                <td>Tarikh Berdaftar</td>
                <td className="CUSTREGISTER"></td>
            </tr>
            <tr>
              <td className="tableHeader_ApplicantData" colSpan='2'>Maklumat Panggilan</td>
            </tr>
            <tr>
                <td>Email</td>
                <td className="CUSTEMAIL"></td>
            </tr>
            <tr>
                <td>No. Peribadi</td>
                <td className="CUSTPERSONALNUM"></td>
            </tr>
            <tr>
                <td>No. Kecemasan</td>
                <td className="CUSTEMERGENCYNUM"></td>
            </tr>
            <tr>
              <td className="tableHeader_ApplicantData" colSpan='2'>Maklumat Pekerjaan</td>
            </tr>
            <tr>
                <td>Nama Majikan</td>
                <td className="EMPLOYNAME"></td>
            </tr>
            <tr>
                <td>Pangkat & Pekerjaan</td>
                <td className="EMPLOYPOSITION"></td>
            </tr>
            <tr>
                <td>Alamat Majikan</td>
                <td className="EMPLOYADDRESS"></td>
            </tr>
            <tr>
                <td>Sektor Industri</td>
                <td className="EMPLOYINDUSTRY"></td>
            </tr>
            <tr>
              <td className="tableHeader_ApplicantData" colSpan='2'>Maklumat Pencadang</td>
            </tr>
            <tr>
                <td>Pencadang YDH Ayah</td>
                <td className="SUGGESTAYAH"></td>
            </tr>
            <tr>
                <td>Majlis Pencadang</td>
                <td className="MAJISTRETNAME"></td>
            </tr>
            <tr>
                <td>Jawatan Dalam Majlis</td>
                <td className="MAJISTRETPOSTION"></td>
            </tr>
            <tr>
                <td>No. Phone Pencadang</td>
                <td className="MAJISTRETPHONE"></td>
            </tr>
            <tr>
              <td className="tableHeader_ApplicantData" colSpan='2'>Maklumat Pendaftaran</td>
            </tr>
            <tr>
                <td>Berdaftar Di Negeri</td>
                <td className="RESSTATE"></td>
            </tr>
            <tr>
                <td>Jawatan dalam PLG</td>
                <td className="RESPOSITION"></td>
            </tr>
            <tr>
                <td>PLG Parlimen</td>
                <td className="RESPARLIMEN"></td>
            </tr>
            <tr>
                <td>No. Phone Pengerusi</td>
                <td className="RESPHONE"></td>
            </tr>
            <tr>
              <td className="tableHeader_ApplicantData billPayment" colSpan='2'>Bukti Pembayaran</td>
            </tr>
            <tr>
                <td>No. Rujukan Resit</td>
                <td className="TRANSACID"></td>
            </tr>
            <tr>
                <td>Tarikh Pembayaran</td>
                <td className="PAYMENTDATE"></td>
            </tr>
            <tr>
                <td>Status Pembayaran</td>
                <td className="APPROVALSTATUS"></td>
            </tr>
            <tr>
                <td>Kod Bill</td>
                <td className="BILLCODE"></td>
            </tr>
            </table>
            <button className="ToyyibPay_Redirect" onClick={
              () => {
                window.open("https://toyyibpay.com/index.php/bill", '_blank').focus();
              }
            }>Lihat Lebih Lanjut di ToyyibPay</button>
          </div>
        </div>
      </div>
    </div>
  );
}

function editCurrentElement(){
  const checkPass = () => {
    var checkValidate = 0;

    $('.CPCont').children(":odd").map((i,value) =>{
      if($(value).val() != ""){
        checkValidate++;
      } else {
        checkValidate--
      }
    })

    if(checkValidate < 4){
      $('.FormValidation').html("Sila pastikan semua maklumat diisi")
      $('.confirmEditAdmins').prop('disabled',true)
    } else {
      var input1 = $($('.CPCont').children(":odd")[2]).val()
      var input2 = $($('.CPCont').children(":odd")[3]).val()
      if(input1 != input2){
        $('.FormValidation').html("Pastikan Kata Laluan adalah sama!")
        $('.confirmEditAdmins').prop('disabled',true)
      } else {
        $('.FormValidation').html("Kata Laluan adalah Sama")
        $('.confirmEditAdmins').prop('disabled',false)
      }
    }
  } 

  return (
    <div class='ChangePassword'>
      <div className="CPCont">
        <label><BsFillPersonLinesFill/></label>
        <input type='text' id="FullName" onChange={() => checkPass()} placeholder="Nama Penuh"/>
      </div>

      <div className="CPCont">
        <label><BsFillPostcardFill/></label>
        <input type='text' id="ICNum" onChange={() => checkPass()} placeholder='No. Kad Pengenalan'/>
      </div>

      <div className="CPCont">
      <label><BsLockFill /></label>
      <input type='text' id="Password" className="CPNewPassword" onChange={() => checkPass()} placeholder="Kata Laluan"/>
      </div>

      
      <div className="CPCont">
      <label><BsLockFill /></label>
      <input type='text' className="CPRewritePassword" onChange={() => checkPass()} placeholder="Sahkan kata Laluan"/>
      </div>

        <div className="FormValidation">
          Pastikan Kata Laluan adalah sama!
        </div>
    </div>
  );
}

function iconContainer(e) {
  if(e == "Update"){
    return (
      <div className='SwalIcon'>
        <BsCheck/>
        <div class='errorMessage successCont'>Maklumat telah Diubah</div>
      </div>
    );
  } else if(e == "Delete"){
    return (
      <div className='SwalIcon'>
        <BsTrashFill/>
        <div class='errorMessage'>Maklumat Telah Dipadam</div>
      </div>
    )
  } else if(e == "ReupdateApprove"){
    return (
      <div className='SwalIcon'>
        <BsExclamationCircleFill/>
        <div class='errorMessage successCont'>Maklumat Telah Diubah<br/>Sila nilai maklumat pemohon</div>
      </div>
    )
  } else if(e == "UpdateApprove"){
    return (
      <div className='SwalIcon'>
        <BsCheck/>
        <div class='errorMessage'>Maklumat Telah Disahkan<br/> Email telah dihantar</div>
      </div>
    )
  } else if(e == "SendEmail"){
    return (
      <div className='SwalIcon'>
        <BsCheck/>
        <div class='errorMessage successCont'>Email berjaya dihantar</div>
      </div>
    );
  }
}

function validateDuplicate(e){
  if(e == "Edit"){
    return (
        <div className='MessageContainer'>
            <BsXLg/>
            <div class='errorMessage'>
                Membership ID sudah digunakan<br/> 
                Sila gunakan No. Identiti lain
            </div>
        </div>
    );
  } else {
    return (
        <div className='MessageContainer'>
            <BsXLg/>
            <div class='errorMessage'>
                Kad Pengenalan sudah Berdaftar<br/> 
                Sila semak semula data pengguna
            </div>
        </div>
    );
  }
}

function reloadPopup(){
  return (
      <div className="MessageContainer">
          <BsCheck/>
          <div class='errorMessage'>
              Data anda telah dihantar<br/>
              Maklumat Pengguna telah ditambah ke dalam sistem
          </div>
      </div>
  )
}

export default ListApplicant;
