import './LoginPage.css';
import '../main.css';
import $ from 'jquery';
import * as React from 'react';
import { BsFillPostcardFill,BsLockFill,BsDoorOpenFill,BsXCircleFill } from "react-icons/bs";
import { useNavigate} from 'react-router-dom';
import axios from 'axios';
import { getSHA256Hash } from "boring-webcrypto-sha256";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function LoginPage() {
    let navigate = useNavigate(); // 'navigate' should be lowercase

    const CheckData = () => {
        var loginForm = new FormData();
        getSHA256Hash($('.Pass').val()).then(e => { 
            loginForm.append('AdminICNum',$('.ICNum').val())
            loginForm.append('AdminPass',e)  
            loginForm.append('AuthKey',process.env.REACT_APP_AUTH_KEY)  
             
            axios.post(process.env.REACT_APP_URL_DATABASE+ 'plgsystem/Admin/login.php',loginForm).then(res => {
                if(res.status == 200){
                    if(res.data.status == 200){
                        navigate('/Admin/ListApplicant')
                        sessionStorage.setItem('tokenAuth',res.data.jwt_auth)
                    } else {
                        withReactContent(Swal).fire({
                            position: "top-end",
                            html : iconContainer(),
                            showConfirmButton: false,
                            customClass: {
                                container:"LoginSwalForm",
                                popup:"swalPopupForm",
                                title:"swalTitleForm"
                            },
                            timerProgressBar: true
                        })

                        $('body').removeClass('swal2-height-auto')
                        $('.LoginSwalForm .swal2-container').css('background','none !important')
                    }
                }
            })
        })
    }

    return(
    <div className="LoginPageContainer">
        <div className="LoginPageComp">
            <div className="LogoCont">
                <div className='LogoImage'/>
                <div className="LogoTitle">
                    Pertubuhan Lima<br/> Generasi Malaysia
                </div>
            </div>

            <div className="InputLogin">
                <div className="userNameContainer">
                    <label><BsFillPostcardFill/>  IC Number</label>
                    <input className="ICNum" type='number' placeholder='012345-67-8910'/>
                </div>
                <div className="passwordContainer">
                    <label><BsLockFill/> Password</label>
                    <input className='Pass' type='password' placeholder='**********'/>
                </div>

                <div className="ButtonContainer">
                    <button onClick={()=> CheckData()}><BsDoorOpenFill/> Sign In</button>
                </div>

                <div className="NeedHelp">
                    Forgot Password? Try Contact Our Admin at <br/>
                    admin@plgmalaysia.com
                </div>
            </div>
        </div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
    </div>
  );
}

function iconContainer() {
    return (
    <div className='SwalIcon'>
        <BsXCircleFill/>
        <div class='errorMessage'>
            <div className='errorTitle'>
                Maklumat Tidak Dijumpai
            </div>
            <div className='errorSubTitle'>
                Semak Kata Laluan & Kad Pengenalan 
            </div>
        </div>
    </div>
    );
  }

export default LoginPage;
