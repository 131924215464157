import React from 'react';
import $ from 'jquery';
import './Form.css';
import {
    BsCreditCard2FrontFill,
    BsFillPersonFill ,
    BsPersonFillGear,
    BsFillPersonBadgeFill,
    BsThreads,
    BsFillPinMapFill ,
    BsPinMap,
    BsFillTelephoneFill,
    BsExclamationCircleFill ,
    BsFillSuitcaseLgFill,
    BsCheck,
    BsXLg,
    BsFacebook, 
    BsEnvelope,
    BsFillHospitalFill ,
    BsPeopleFill,
    BsPersonFill
 } from "react-icons/bs";
import { Helmet } from 'react-helmet-async';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { useNavigate,Link} from 'react-router-dom';
import axios from 'axios';
import { getSHA256Hash } from "boring-webcrypto-sha256";
import ReactGA  from "react-ga4";

function reloadPopup(){
    return (
        <div className="MessageContainer">
            <BsCheck/>
            <div class='errorMessage'>
                Data anda telah dihantar<br/>
                Semak email dalam masa terdekat<br/>
                Untuk dapatkan link pembayaran<br/><br/>
                Anda akan dialihkan ke Halaman Utama
            </div>
        </div>
    )
}

function Form() {
    let navigate = useNavigate();
    const [checkUpdate, setCheckUpdate] = React.useState(false);

    ReactGA.send({
        hitType : "pageview",
        page : "/CustForm",
        title : "CustForm"
    })
    
    const showBasicCred = (e) => {
        $(e).parent().children('li').map((i,value) => {
            $(value).removeClass('SelectedTab')
        })
        
        $('.FormTitle').html("Kami mahu mengenal anda")
        $('.FormDesc').html("Sila Masukkan maklumat peribadi")

        $(e).addClass('SelectedTab')
        $('.FormContainer').show()
        $('.SuggestionSect').hide()
        $('.ContactInfo').hide()
        $('.EmploymentContainer').hide()
        $('.RegisterCont').hide()

        $('.BackAddress').hide()
        $('.BackSuggest').hide()
        $('.BackContactInfo').hide()
        $('.BackRegister').hide()

        $('.NextRegister').hide()
        $('.NextBasicCred').show()
        $('.NextContactInfo').hide()
        $('.NextSuggest').hide()
        $('.SubmitAddress').hide()
    }
    
    const showContactInfo = (e) => {
        $(e).parent().children('li').map((i,value) => {
            $(value).removeClass('SelectedTab')
        })

        $('.FormTitle').html("Kami ingin menghubungi anda")
        $('.FormDesc').html("Masukkan maklumat nombor telefon anda")

        $(e).addClass('SelectedTab')
        $('.FormContainer').hide()
        $('.ContactInfo').show().css('display', 'flex');
        $('.SuggestionSect').hide()
        $('.EmploymentContainer').hide()
        $('.RegisterCont').hide()

        $('.BackSuggest').hide()
        $('.BackAddress').hide()
        $('.BackContactInfo').show()
        $('.BackRegister').hide()

        $('.NextRegister').hide()
        $('.NextSuggest').hide()
        $('.NextBasicCred').hide()
        $('.NextContactInfo').show()
        $('.SubmitAddress').hide()
    }

    const showRegister = (e) => {
        $(e).parent().children('li').map((i,value) => {
            $(value).removeClass('SelectedTab')
        })
        
        $('.FormTitle').html("Maklumat anda hampir lengkap")
        $('.FormDesc').html("Kami ingin ketahui kedudukan anda dalam Parlimen")

        $(e).addClass('SelectedTab')
        $('.FormContainer').hide()
        $('.SuggestionSect').hide()
        $('.ContactInfo').hide()
        $('.EmploymentContainer').hide();
        $('.RegisterCont').show().css('display', 'flex');

        $('.BackSuggest').show()
        $('.BackAddress').hide()
        $('.BackContactInfo').hide()
        $('.BackRegister').hide()

        $('.NextRegister').hide()
        $('.NextBasicCred').hide()
        $('.NextSuggest').hide()
        $('.NextContactInfo').hide()
        $('.SubmitAddress').show()
    }

    const showSuggest = (e) => {
        $(e).parent().children('li').map((i,value) => {
            $(value).removeClass('SelectedTab')
        })
        
        $('.FormTitle').html("Anda berdaftar di bawah Parlimen")
        $('.FormDesc').html("Kami ingin ketahui siapakah pencadang anda?")

        $(e).addClass('SelectedTab')
        $('.FormContainer').hide()
        $('.ContactInfo').hide()
        $('.EmploymentContainer').hide();
        $('.RegisterCont').hide();
        $('.SuggestionSect').show().css('display', 'flex');

        $('.BackSuggest').hide()
        $('.BackAddress').hide()
        $('.BackContactInfo').hide()
        $('.BackRegister').show()

        $('.NextRegister').hide()
        $('.NextBasicCred').hide()
        $('.NextSuggest').show()
        $('.NextContactInfo').hide()
        $('.SubmitAddress').hide()
    }
    
    const showEmploymentContainer = (e) => {
        $(e).parent().children('li').map((i,value) => {
            $(value).removeClass('SelectedTab')
        })
        
        $('.FormTitle').html("Anda mempunyai Pekerjaan?")
        $('.FormDesc').html("Kami ingin mengetahui status pekerjaan anda")

        $(e).addClass('SelectedTab')
        $('.FormContainer').hide()
        $('.ContactInfo').hide()
        $('.EmploymentContainer').show().css('display', 'flex');
        $('.RegisterCont').hide()
        $('.SuggestionSect').hide()

        $('.BackSuggest').hide()
        $('.BackAddress').show()
        $('.BackContactInfo').hide()
        $('.BackRegister').hide()

        $('.NextRegister').show()
        $('.NextBasicCred').hide()
        $('.NextContactInfo').hide()
        $('.NextSuggest').hide()
        $('.SubmitAddress').hide()
    }

    const checkScreenSize = () => {
        if(window.innerWidth > 600){
            return "top-end"
        } else {
            return "center"
        }
    }

    const showSwal = () => {
        var emptyValue = "";
        var dataCust = new FormData();
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        $('.IconHolder').children(":odd").map((i,value) => {
            if(value.value == "" && value.className != 'OfficeNumber') {
                if(value.placeholder == undefined){
                    if(value.id == "RESSTATE"){
                        emptyValue += "<li>Pendaftaran Negeri PLG</li>"
                    } else {
                        emptyValue += "<li>" + value.id + "</li>"
                    }
                } else {
                    emptyValue += "<li>" + value.placeholder + "</li>"
                }
            } else {
                dataCust.append(value.className,value.value)
            }
        })

        if(!checkUpdate){
            if(!($('.selectedFile')[0].files.length > 0)){
                emptyValue += "<li> Gambar Peribadi</li>"
            } else {
                dataCust.append("FilePict",$('.selectedFile')[0].files[0])
            }
        } else {
            if($('.selectedFile')[0].files.length > 0){
                dataCust.append("FilePict",$('.selectedFile')[0].files[0])
            } 

            dataCust.append('CustID',window.location.href.split("?")[1].split("&")[0])
        }

        if(emptyValue == "") {
            if(emailPattern.test($('.Email').val())){
                if(checkUpdate){
                    axios.post(process.env.REACT_APP_URL_DATABASE + '/plgsystem/Customer/editDataSpec.php',dataCust,{withCredentials:true}).then((resCust) =>{
                        if(resCust.status == 200){
                            console.log(resCust)
                            if(resCust.data.status == undefined){
                                withReactContent(Swal).fire({
                                        position: checkScreenSize(),
                                        html : reloadPopup(),
                                        showConfirmButton: false,
                                        customClass: {
                                            container:"swalContainerForm",
                                            popup:"successMessage",
                                            title:"swalTitleForm"
                                        },
                                        timerProgressBar: true
                                        ,timer: 3000
                                    })
                                    .then(res => {
                                        if(res.isDismissed){
                                            navigate('/')
                                        }
                                    })
                
                                    $('body').removeClass('swal2-height-auto')
                            } else {
                                withReactContent(Swal).fire({
                                        position: checkScreenSize(),
                                        html : validateDuplicate(),
                                        showConfirmButton: false,
                                        customClass: {
                                            container:"swalContainerForm",
                                            popup:"swalPopupForm",
                                            title:"swalTitleForm"
                                        },
                                        timerProgressBar: true
                                        ,timer: 3000
                                    })
                
                                    $('body').removeClass('swal2-height-auto')
                            }
                        }
                    })
                } else {
                    axios.post(process.env.REACT_APP_URL_DATABASE + '/plgsystem/Customer/addData.php',dataCust,{withCredentials:true}).then((resCust) =>{
                        if(resCust.status == 200){
                            console.log(resCust)
                            if(resCust.data.status == undefined){
                                withReactContent(Swal).fire({
                                        position: checkScreenSize(),
                                        html : reloadPopup(),
                                        showConfirmButton: false,
                                        customClass: {
                                            container:"swalContainerForm",
                                            popup:"successMessage",
                                            title:"swalTitleForm"
                                        },
                                        timerProgressBar: true
                                        ,timer: 3000
                                    })
                                    .then(res => {
                                        if(res.isDismissed){
                                            navigate('/')
                                        }
                                    })
                
                                    $('body').removeClass('swal2-height-auto')
                            } else {
                                withReactContent(Swal).fire({
                                        position: checkScreenSize(),
                                        html : validateDuplicate(),
                                        showConfirmButton: false,
                                        customClass: {
                                            container:"swalContainerForm",
                                            popup:"swalPopupForm",
                                            title:"swalTitleForm"
                                        },
                                        timerProgressBar: true
                                        ,timer: 3000
                                    })
                
                                    $('body').removeClass('swal2-height-auto')
                            }
                        }
                    })
                }
            } else {
                withReactContent(Swal).fire({
                    position: checkScreenSize(),
                    html : validateEmail(),
                    showConfirmButton: false,
                    customClass: {
                        container:"swalContainerForm",
                        popup:"swalPopupForm",
                        title:"swalTitleForm"
                    },
                    timerProgressBar: true
                    ,timer: 3000
                })
                
                $('body').removeClass('swal2-height-auto')
            }
        } else {
            withReactContent(Swal).fire({
                position: checkScreenSize(),
                html : "<div class='errorMessage'>Sila Pastikan Maklumat dibawah lengkap : </div><ul class='ListEmpty'>"+ emptyValue +"</ul>",
                showConfirmButton: false,
                customClass: {
                    container:"swalContainerForm",
                    popup:"swalPopupForm",
                    title:"swalTitleForm"
                },
                timerProgressBar: true
                ,timer: 3000
            })
            
            $('body').removeClass('swal2-height-auto')
        }
      }

      const errorSwal = () => {
        withReactContent(Swal).fire({
            position: () => {
                if(window.innerWidth > 600) {
                    return "top-end";
                } else {
                    return "center";
                }
            },
            html : "<div class='errorMessage InvalidPict'>Hanya Gambar .png, .jpeg, .jpg sahaja yang diterima. Sila Tukar Gambar</div>",
            showConfirmButton: false,
            customClass: {
                container:"swalContainerForm",
                popup:"swalPopupForm",
                title:"swalTitleForm"
            },
            timerProgressBar: true
            ,timer:3000
        })
        
        $('body').removeClass('swal2-height-auto')
      }

      const errorLink = () => {
        return (
            <div className="MessageContainer">
                <BsXLg/>
                <div class='errorMessage'>
                    Link Verifikasi ini telah tamat tarikh Luput<br/>
                    Sila cuba sekali lagi atau daftar akaun baharu
                </div>
            </div>
        )
    }

      const readURL = (input) => {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            var validFile =  ["jpg", "jpeg", "png"];
            var checkFile = 0;

            for(var i = 0;i < validFile.length;i++){
                if(input.files[0].type.split("/")[1] === validFile[i]){
                    checkFile += 1;
                }
            }

            if(checkFile > 0){
                reader.onload = function (e) {
                    $('.SubmitImage').css('background-image',"url("+ e.target.result +")");
                }
        
                reader.readAsDataURL(input.files[0]);
            } else {
                errorSwal()
            }
        }
    }

      React.useEffect(()=>{
        const currentDate = new Date();
        var date = currentDate.getDate() + "-" + (currentDate.getMonth() + 1) + "-" + currentDate.getFullYear();
        $('.NextBasicCred').show()

        $('input').keypress(function(e){
            var txt = String.fromCharCode(e.which);
            if (!txt.match(/[A-Za-z0-9&./ ']/)) {
                return false;

                if (e.which !== 32) {
                    return false;
                }
            }
        })
        
        if (window.location.href.indexOf("?") > -1) {
            getSHA256Hash(date).then(e => {
                if(e == window.location.href.split("?")[1].split("&")[1]){
                        setCheckUpdate(true)
                        var CustCheck = new FormData();
                        CustCheck.append('CustID',window.location.href.split("?")[1].split("&")[0])
                        CustCheck.append('TimeExpired',window.location.href.split("?")[1].split("&")[1])
            
                        axios.post(process.env.REACT_APP_URL_DATABASE + "plgsystem/Customer/viewDataSpec.php",CustCheck).then(res => {
                            if(res.status == 200){
                                for(var i = 0;i <= Object.keys(res.data[0]).length - 1;i++){
                                    if(isNaN(parseInt(Object.keys(res.data[0])[i]))){
                                        if(Object.keys(res.data[0])[i] == "RESSTATE"){
                                            $('#' + Object.keys(res.data[0])[i] + " option[value='"+ Object.values(res.data[0])[i] +"']").prop("selected",true)
                                        } else if(Object.keys(res.data[0])[i] == "CUSTPIC"){
                                            $('.'+Object.keys(res.data[0])[i]).css({backgroundImage : 'url('+process.env.REACT_APP_URL_DATABASE + Object.values(res.data[0])[i]+''})
                                        } else {
                                            $('#' + Object.keys(res.data[0])[i]).val(Object.values(res.data[0])[i])
                                        }
                                    }
                                }
                            }
                        })
                } else {
                    withReactContent(Swal).fire({
                        position: checkScreenSize(),
                        html : errorLink(),
                        showConfirmButton: false,
                        customClass: {
                            container:"swalContainerForm",
                            popup:"errorEmail",
                            title:"swalTitleForm"
                        },
                        timerProgressBar: true
                        ,timer: 3000
                    })
                    setCheckUpdate(false)
                }
            })           
        }
      })

    return(
        <div className="FormSubmitContainer">
            <Helmet>
                {/* Primary Meta Tags */}
                <title>Portal Pertubuhan Lima Generasi </title>
                <meta name="title" content="Portal Pertubuhan Lima Generasi" />
                <meta name="description" content="Ini adalah halaman bagi mengisi borang penyertaan PLG, Sertai Kami dalam menghulurkan bantuan kepada yang memerlukan. Rapatkan Ukhuwah, Kuatkan Pegangan." />

                {/* Open Graph / Facebook  */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://plgmalaysia.com/CustForm" />
                <meta property="og:title" content="Portal Pertubuhan Lima Generasi " />
                <meta property="og:description" content="Ini adalah halaman bagi mengisi borang penyertaan PLG, Sertai Kami dalam menghulurkan bantuan kepada yang memerlukan. Rapatkan Ukhuwah, Kuatkan Pegangan." />
                <meta property="og:image" content="https://plgmalaysia.com/image/miscellanous/LogoWebsite.png" />

                {/* Twitter  */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://plgmalaysia.com/CustForm" />
                <meta property="twitter:title" content="Portal Pertubuhan Lima Generasi" />
                <meta property="twitter:description" content="Ini adalah halaman bagi mengisi borang penyertaan PLG, Sertai Kami dalam menghulurkan bantuan kepada yang memerlukan. Rapatkan Ukhuwah, Kuatkan Pegangan." />
                <meta property="twitter:image" content="https://plgmalaysia.com/image/miscellanous/LogoWebsite.png" />
            </Helmet>
            <div className="headerMain FormHeader">
                <div className='LogoImage MainLogo LogoHeader'/>
                <div className="ContainerBackButtonHome">
                    <Link to="/"><button className='BackButtonHome' id="BackButtonHome">Kembali</button></Link>
                </div>
            </div>
            <div className="FormDoubleContainer">
                <div className="FormContainedContainer">
                <div className="FormTab">
                    <ul className='FormDivideTab'>
                    <li className='SelectedTab' onClick={(e) => showBasicCred(e.target)}>Maklumat Peribadi</li>
                    <li onClick={(e) => showContactInfo(e.target)}>Maklumat Panggilan</li>
                    <li onClick={(e) => showEmploymentContainer(e.target)}>Maklumat Pekerjaan</li>
                    <li onClick={(e) => showSuggest(e.target)}>Pencadang</li>
                    <li onClick={(e) => showRegister(e.target)}>Pendaftaran</li>
                    </ul>
                </div>
                <div className='FormOuterContainer'>
                <div className='LogoContainerSubmit'>
                </div>
            
            <div className="HolderForm">
                <div className="FormExp">
                    <div className='FormTitle'>Kami mahu mengenal anda</div>
                    <div className='FormDesc'>Sila Masukkan maklumat peribadi</div>
                </div>

            <div className='FormContainer'>
                <div className="ImageContainer">
                        <div className="SubmitImage CUSTPIC"/>
                        <div className="ImageFile IconHolder">
                        <input type="file" className="selectedFile" onChange={(e) => readURL(e.target)}/>
                        <input type="button" className='FilePict' value="Pilih Kad Pengenalan" onClick={() => $('.selectedFile').click()} />
                        </div>
                    </div>

                <div className="CredentialContainer">
                    <div className="IconHolder">
                        <div className="IconContainerTab">
                            <BsFillPersonFill />
                        </div>
                        <input type="text" className='FullName' id="CUSTNAME" placeholder='Nama Penuh' required/>
                    </div>
                    <div className="IconHolder">
                        <div className="IconContainerTab">
                            <BsCreditCard2FrontFill/>
                        </div>
                        <input type="number" className='ICNumber' id="CUSTICNUM" placeholder='No. Kad Pengenalan' required/>
                    </div>
                    <div className="IconHolder">
                        <div className="IconContainerTab">
                            <BsPersonFillGear/>
                        </div>
                        <input type="text" className='BirthDate' id="CUSTBIRTHDATE" onFocus={(e) => e.target.type='date'} onBlur={(e) => e.target.type='text'} placeholder='Tarikh Lahir' required/>
                    </div>
                    <div className="IconHolder">
                        <div className="IconContainerTab">
                            <BsFillPersonBadgeFill/>
                        </div>
                        <input type="text" className='LocationIC' id="CUSTICADDRESS" placeholder='Alamat (Kad Pengenalan)' required/>
                    </div>
                </div>
            </div>

            
            <div className="ContactInfo">
            <div className="IconHolder">
                    <div className="IconContainerTab">
                        <BsThreads />
                    </div>
                        <input type="email" className='Email' id="CUSTEMAIL" placeholder='Email' required/>
                </div>
                
                <div className="IconHolder">
                    <div className="IconContainerTab">
                        <BsFillTelephoneFill  />
                    </div>
                        <input type="number" className='PersonalNumber' id="CUSTPERSONALNUM" placeholder='No. Phone Peribadi' required/>
                </div>
                
                <div className="IconHolder">
                    <div className="IconContainerTab">
                        <BsExclamationCircleFill   />
                    </div>
                        <input type="number" className='EmergencyNumber' id="CUSTEMERGENCYNUM" placeholder='No. Phone Kecemasan' required/>
                </div>
                
                <div className="IconHolder">
                    <div className="IconContainerTab">
                        <BsFillPinMapFill />
                    </div>
                    <input type="text" className='Address' id="CUSTCURRENTADDRESS" placeholder='Alamat Semasa' required/>
                </div>
            </div>

            <div className="EmploymentContainer">
                <div className="IconHolder">
                    <div className="IconContainerTab">
                        <BsFillHospitalFill/>
                    </div>
                    <input type="text" className='EmployName' id="EMPLOYNAME" placeholder='Nama Majikan' required/>
                </div>

                <div className="IconHolder">
                    <div className="IconContainerTab">
                        <BsFillSuitcaseLgFill/>
                    </div>
                    <input type="text" className='EmployLvl' id="EMPLOYPOSITION" placeholder='Pangkat & Pekerjaan' required/>
                </div>
                
                <div className="IconHolder">
                    <div className="IconContainerTab">
                        <BsFillPinMapFill/>
                    </div>
                    <input type="text" className='LocationEmploy' id="EMPLOYADDRESS" placeholder='Alamat Majikan' required/>
                </div>

                <div className="IconHolder">
                    <div className="IconContainerTab">
                        <BsPeopleFill/>
                    </div>
                    <input type="text" className='SectIndustry' id="EMPLOYINDUSTRY" placeholder='Sektor Industri' required/>
                </div>
            </div>

            <div className='SuggestionSect'>
                <div className="IconHolder">
                    <div className="IconContainerTab">
                        <BsPersonFill />
                    </div>
                        <input type="text" className='SuggestedBy' id="SUGGESTAYAH" placeholder='Pencadang YDH Ayah' required/>
                </div>
                <div className="IconHolder">
                    <div className="IconContainerTab">
                        <BsPersonFill />
                    </div>
                        <input type="text" className='MajistretSuggest' id="MAJISTRETNAME" placeholder='Majlis Pencadang' required/>
                </div>
                <div className="IconHolder">
                    <div className="IconContainerTab">
                        <BsFillHospitalFill />
                    </div>
                        <input type="text" className='JawatanMajlis' id="MAJISTRETPOSTION" placeholder='Jawatan Dalam Majlis' required/>
                </div>
                
                <div className="IconHolder">
                    <div className="IconContainerTab">
                        <BsFillTelephoneFill  />
                    </div>
                        <input type="number" className='MajlisNumber' id="MAJISTRETPHONE" placeholder='No. Phone Pencadang' required/>
                </div>
            </div>

            <div className="RegisterCont">
            <div className="IconHolder">
                    <div className="IconContainerTab">
                        <BsPinMap/>
                    </div>
                    <select className="ParliamentState" id="RESSTATE">
                        <option selected value="">Pilih Pendaftaran Negeri PLG</option>
                        <option value="Selangor">Selangor</option>
                        <option value="Kuala Lumpur">Kuala Lumpur</option>
                        <option value="Putrajaya">Putrajaya</option>
                        <option value="Pahang">Pahang</option>
                        <option value="Perak">Perak</option>
                        <option value="Terengganu">Terengganu</option>
                        <option value="Sabah">Sabah</option>
                        <option value="Sarawak">Sarawak</option>
                        <option value="Kedah">Kedah</option>
                        <option value="Kelantan">Kelantan</option>
                        <option value="Perlis">Perlis</option>
                        <option value="Negeri Sembilan">Negeri Sembilan</option>
                        <option value="Pulau Pinang">Pulau Pinang</option>
                        <option value="Melaka">Melaka</option>
                        <option value="Johor">Johor</option>
                    </select>
                </div>
            <div className="IconHolder">
                    <div className="IconContainerTab">
                        <BsPersonFill />
                    </div>
                        <input type="text" className='JawatanPLG' id="RESPOSITION" placeholder='Jawatan Dalam PLG' required/>
                </div>
                
                <div className="IconHolder">
                    <div className="IconContainerTab">
                        <BsFillHospitalFill />
                    </div>
                        <input type="text" className='ParliamentName' id="RESPARLIMEN" placeholder='PLG Parlimen' required/>
                </div>

                <div className="IconHolder">
                    <div className="IconContainerTab">
                        <BsFillTelephoneFill  />
                    </div>
                        <input type="number" className='PengerusiNumber' id="RESPHONE" placeholder='No. Phone Pengerusi' required/>
                </div>
            </div>
            </div>
            
            
            <div className='buttonSubmitCont'>
                    <button className="BackContactInfo" onClick={() => {$('.FormDivideTab').children('li')[0].click()}}>Back</button>
                    <button className="BackAddress" onClick={() => {$('.FormDivideTab').children('li')[1].click()}}>Back</button>
                    <button className="BackRegister" onClick={() => {$('.FormDivideTab').children('li')[2].click()}}>Back</button>
                    <button className="BackSuggest" onClick={() => {$('.FormDivideTab').children('li')[3].click()}}>Back</button>

                    <button className="NextBasicCred" onClick={() => {$('.FormDivideTab').children('li')[1].click()}}>Next</button>
                    <button className='NextContactInfo' onClick={() => {$('.FormDivideTab').children('li')[2].click()}}>Next</button>
                    <button className='NextRegister' onClick={() => {$('.FormDivideTab').children('li')[3].click()}}>Next</button>
                    <button className='NextSuggest' onClick={() => {$('.FormDivideTab').children('li')[4].click()}}>Next</button>
                    <button className='SubmitAddress' onClick={() => {showSwal()}} >Hantar Permohonan</button>
            </div>
                </div>
                </div>

                <div className="footerMain">
                <div className="footerHoldCont">
                    <div className='LogoImage MainLogo'/>
                    <div className="footerContent">
                        <div className="ListMenuFooter">
                            <div className='ListMenuFTitle'>Pautan Navigasi</div>
                            <ul className='ListFooter'>
                                <li><a href="#AboutUs" onClick={() => navigate("/#AboutUs")}>Tentang Kami</a></li>
                                <li><a href="#Develop" onClick={() => navigate("/#Develop")}>Carta Organisasi</a></li>
                                <li><a href="#Develop" onClick={() => navigate("/#Develop")}>Merchandise</a></li>
                                <li><a href="#News&Act" onClick={() => navigate("/#News&Act")}>Berita & Aktiviti</a></li>
                                <li><a href="#Register" onClick={() => navigate("/#Register")}>Daftar Ahli</a></li>
                            </ul>
                        </div>

                        <div className="OfficeLocation">
                            <div className='ListMenuFTitle'>Lokasi Pejabat</div>
                            <div className='LocationDesc'>
                                40-1, Jalan S2 F1 Garden Homes,
                                Seremban 2, 70300 Seremban,
                                Negeri Sembilan 
                            </div>
                        </div>

                        <div className="SocialMedia">
                            <div className='ListMenuFTitle'>Media Sosial</div>
                            <ul className='ListSocialMedia'>
                                <li><a href="https://www.facebook.com/PakisMalaysia" target="__blank"><BsFacebook/> Pertubuhan Lima Generasi HQ</a></li>
                                <li><a href="https://wa.me/+60178877115" target="__blank"><BsFillTelephoneFill/> 017-8877115</a></li>
                                <li><BsEnvelope/> admin@plgmalaysia.com</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="CopyrightDisclaim">
                    <img className='LogoContClic ImageFull' src={'../image/miscellanous/Clictec.png'} />
                    <div className='LogoDesc'>
                        Laman Web ini dikendalikan oleh ClicTec Solution 
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
};

function validateDuplicate(){
    return (
        <div className='MessageContainer'>
            <BsXLg/>
            <div class='errorMessage validateEmail'>
                Anda sudah Berdaftar<br/> 
                Hubungi Admin untuk semak data<br/>
                atau ubah maklumat melalui email
            </div>
        </div>
    );
}

function validateEmail(){
    return (
        <div className='MessageContainer'>
            <BsXLg/>
            <div class='errorMessage validateEmail'>
                Sila Pastikan email adalah sah
            </div>
        </div>
    );
}

  export default Form;
  
