import React from 'react';
import $ from 'jquery';
import './MainPage.css';
import { useNavigate,Link} from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import ImageSwiper from './Event.json';
import Activity from './Activity.json';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {BsFacebook, BsFillTelephoneFill, BsEnvelope, BsSearch, BsCheck ,BsXLg} from "react-icons/bs";
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import ReactGA  from "react-ga4";

function Form() {
    let navigate = useNavigate();
    const [slides,setSlides] = React.useState(0);

    ReactGA.send({
        hitType : "pageview",
        page : "/",
        title : "MainPage"
    })

    const setSlidesPreview = () => {
        setSlides(
            window.innerWidth <= 550 ? 1: 
            window.innerWidth <= 720 ? 2: 
            window.innerWidth > 720 ? 3: 
            0
        )
    }

    const errorEmail = () => {
        return (
            <div className="MessageContainer">
                <BsXLg/>
                <div className='errorMessage'>
                    Email yang dimasukkan adalah tidak berdaftar<br/>
                    Sila cuba sekali lagi atau daftar akaun baharu
                </div>
            </div>
        )
    }

    const successEmail = () => {
        return (
            <div className="MessageContainer">
                <BsCheck/>
                <div className='errorMessage'>
                    Link Verifikasi telah dihantar<br/>
                    Semak email anda dalam masa terdekat
                </div>
            </div>
        )
    }

    const checkEmail = () => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if(emailPattern.test($('#Email').val())){
            $('.EmailValidate').hide()
            $('.confirmEditApplicant').prop('disabled',false)
        } else{
            $('.EmailValidate').show()
            $('.confirmEditApplicant').prop('disabled',true)
            $('.EmailValidate').html('Pastikan Email yang dimasukkan adalah sah')
        }
    }
    
    const checkScreenSize = () => {
        if(window.innerWidth > 600){
            return "top-end"
        } else {
            return "center"
        }
    }

    const emailCont = (e) => {
        return (
          <div className='EmailSwal'>
            <div className='emailDesc'>
              <span className="emailcontent">
                Email {e} sedang disemak<br/>
                Semakan email mengambil masa dalam 10 - 15 saat.<br/>
                Bergantung kepada Kelajuan Internet
              </span> <br/><br/>
              <span className="explicitEmail">
                Jika proses ini terlalu lama, Sila <i>refresh</i> Halaman Utama <br/>
                Tempoh Penghantaran Semasa : &nbsp;
                <span className="countTimer">1</span>&nbsp;saat
              </span>
            </div>
          </div>
        )
      }

    const submitVerifyEmail = () => {
        let timerInterval;
        var dataEmail = new FormData();
        const askEmail = withReactContent(Swal).fire({
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Hantar Link Verifikasi",
            cancelButtonText: "Cancel",
            customClass:{
              actions:'emailVerifyCont',
              confirmButton:"confirmEditApplicant"
            },
            html: submitEmail()
          }).then((result) => {
            if(result.isConfirmed){
                const EmailAddy = $('#Email').val();
                const EmailTimer = withReactContent(Swal).fire({
                    position: "center",
                    html : emailCont(EmailAddy),
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                      var count = 1;
                      Swal.showLoading();
                      timerInterval = setInterval(function(){
                        count++;
                        $('.countTimer').html(count)
                      },1000)
                      },
                      willClose: () => {
                        clearInterval(timerInterval);
                      },
                    customClass: {
                        container:"swalSendEmail",
                        popup:"swalPopupEmail",
                        title:"swalTitleForm"
                    },
                    timerProgressBar: true
                })

                $('body').removeClass('swal2-height-auto')

                dataEmail.append('CustEmail',EmailAddy)
                // dataEmail.append('LinkForm',process.env.REACT_APP_URL_DATABASE)
                axios.post(process.env.REACT_APP_URL_DATABASE + '/plgsystem/Customer/sendVerification.php',dataEmail).then(res =>{
                    if(res.status == 200) {
                        if(res.data.status != 301){
                            withReactContent(Swal).fire({
                                position: checkScreenSize(),
                                html : successEmail(),
                                showConfirmButton: false,
                                customClass: {
                                    container:"swalContainerForm",
                                    popup:"successMessage successEmail",
                                    title:"swalTitleForm"
                                },
                                timerProgressBar: true
                                ,timer: 3000
                            })
                        } else {
                            // EmailTimer.close();
                            withReactContent(Swal).fire({
                                position: checkScreenSize(),
                                html : errorEmail(),
                                showConfirmButton: false,
                                customClass: {
                                    container:"swalContainerForm",
                                    popup:"errorEmail",
                                    title:"swalTitleForm"
                                },
                                timerProgressBar: true
                                ,timer: 3000
                            })
                        }
                    }
                })
            }
          })

        $('.confirmEditApplicant').prop('disabled',true)
    }

    const submitEmail = () => {
        return (
            <div className='SendEmails'>
              <div className="IconHolder EmailCont">
                <div className="IconContainerTab">
                    <BsSearch/>
                </div>
                <input type='email' id="Email" onChange={() => checkEmail()} placeholder="Masukkan Email (Exp : 123@gmail.com)"/>
              </div>

              <div className="EmailValidate">
                Masukkan Email dan Kami akan hantar link verifikasi
              </div>

                <div className="EmailConfirmation">
                    * Link Verifikasi ini akan tamat tarikh luput selepas 1 hari<br/>
                    Jika Link telah tamat tarikh luput Sila gunakan link baharu
                </div>
            </div>
          );
    }

    React.useEffect(() => {
        setSlidesPreview();
    },[])

    return(
        <div className='HomepageContainer'>
            <Helmet>
                {/* Primary Meta Tags */}
                <title>Portal Pertubuhan Lima Generasi </title>
                <meta name="title" content="Portal Pertubuhan Lima Generasi " />
                <meta name="description" content="Selamat Datang ke Website Rasmi Pertubuhan Lima Generasi atau nama singkatan PLG. Diasaskan oleh Ayahkanda PLG Al-Rawa bertujuan untuk memastikan keperluan golongan masyarakat setempat dipenuhi sekaligus menjaga kesejahteraan bangsa dan negara." />

                {/* Open Graph / Facebook  */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://plgmalaysia.com.my" />
                <meta property="og:title" content="Portal Pertubuhan Lima Generasi " />
                <meta property="og:description" content="Selamat Datang ke Website Rasmi Pertubuhan Lima Generasi atau nama singkatan PLG. Diasaskan oleh Ayahkanda PLG Al-Rawa bertujuan untuk memastikan keperluan golongan masyarakat setempat dipenuhi sekaligus menjaga kesejahteraan bangsa dan negara." />
                <meta property="og:image" content="https://plgmalaysia.com.my/image/miscellanous/LogoWebsite.png" />

                {/* Twitter  */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://plgmalaysia.com.my" />
                <meta property="twitter:title" content="Portal Pertubuhan Lima Generasi " />
                <meta property="twitter:description" content="Selamat Datang ke Website Rasmi Pertubuhan Lima Generasi atau nama singkatan PLG. Diasaskan oleh Ayahkanda PLG Al-Rawa bertujuan untuk memastikan keperluan golongan masyarakat setempat dipenuhi sekaligus menjaga kesejahteraan bangsa dan negara." />
                <meta property="twitter:image" content="https://plgmalaysia.com.my/image/miscellanous/LogoWebsite.png" />
            </Helmet>
            <div className="headerMain">
                <div className='LogoImage MainLogo'/>
                <div className="ListMainMenu">
                    <ul className='ListHeader'>
                        <li><a href="#AboutUs">Tentang Kami</a></li>
                        <li><a href="#Develop">Carta Organisasi</a></li>
                        <li><a href="#Develop">Merchandise</a></li>
                        <li><a href="#News&Act">Berita & Aktiviti</a></li>
                        <li><a href="#Register">Daftar Ahli</a></li>
                    </ul>
                </div>
            </div>

            <div className="AboutUsMain" id="AboutUs">
                <div className="MainTitle">
                    <div className='LogoImage LogoGreet'/>
                    <span className="GreetT">Selamat Datang ke Laman Web</span><br/>
                    <span className="WebName">Pertubuhan Lima Generasi Malaysia</span><br/><br/>
                    <div className="WebDesc">
                        <div className="VisiMisi">
                        <b>Visi</b>
                        <br/>
                        "Peneraju utama kebajikan dalam pembangunan insan dan memartabatkan kesejahteraan masyarakat sejagat"
                        <br/><br/>
                        <b>Misi</b>
                        <br/>
                        "Menjadi peneraju, pemangkin & membantu masyarakat dan ahli yang memerlukan"
                        <br/><br/>
                        <b>Moto</b>
                        <br/>
                        "Memartabatkan Insan Ke Arah Kecemerlangan"
                        </div>

                        <div className="Objektif">
                        <b>Objektif</b>
                        <ul>
                            <li>Meningkatkan kompetesi, prestasi, produktiviti dan daya inovasi masyarakat dan ahli</li>
                            <li>Meningkatkan keupayaan dan memberi nafas baharu kepada organisasi dan ahli</li>
                            <li>Meningkatkan kecekapan dan keberkesanan penyampaian perkhidmatan kerajaan pada masyarakat dan ahli</li>
                            <li>Memperluas jaringan kerja dan kolaborasi strategik melalui program fan latihan kerajaan yang tersedia</li>
                        </ul>
                        </div>
                    </div>
                </div>
                <div className='BackImage ImageFull'/>
            </div>

            <div className="ImageContainerH">
                <Swiper
                slidesPerView={slides}
                autoplay={{
                delay: 2500,
                disableOnInteraction: true,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                loop
                >
                {
                    ImageSwiper.map((val, i) => {
                        return (
                            <SwiperSlide key={i}>
                                <div
                                    className="ImageFull pd_x_10"
                                    style={{ backgroundImage: `url(${process.env.REACT_APP_MAIN_URL  + val.filePath})` }}
                                />
                            </SwiperSlide>
                        );
                    })
                }
                </Swiper>
            </div>

            {/* <div className="Organization">
                <div className='FounderPLG ImageFull'/>
                <div className="DescOrgs">
                    <div className="ChartTitle">Carta Organisasi Pertubuhan Lima Generasi</div>
                    <div className="ListMember">
                        awdawdawdawd
                    </div>
                </div>
            </div> */}

            <div className="EventsMain" id="News&Act">
                <div className="ActivityHolder">
                    {
                        Activity.map((val,i) => {
                            return(
                                <div className='ActivityContainer' key={i}>
                                    <div className="ActivityImage ImageFull" style={{ backgroundImage: `url(${process.env.REACT_APP_MAIN_URL  + val.filePath})` }}/>
                                    <div className='ActivityRightCont'>
                                        <div className="ActivityDesc">
                                            <div className="ActivityTitle">
                                                {val.activityName}
                                            </div>
                                            <div className="ActivityLocation">
                                                {val.Location}
                                            </div>
                                            <div className='ActivityDate'>
                                                {val.activityDate}
                                            </div>
                                            <div className='ActivityDetail'>
                                                {val.activityDetail}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>

            <div className="SignUpCont" id="Register">
                <div className="ImageFull SignupImage"/>
                <div className="SignUpDesc">
                    <div className="SignUpTitle">
                        Bantu Kami, Bantu Masyarakat
                    </div>

                    <div className="SignUpDescription">
                        Sertai Kami dalam menghulurkan bantuan kepada yang memerlukan. <br/>
                        Rapatkan Ukhuwah, Kuatkan Pegangan.<br/><br/>
                        Penyertaan untuk menjadi Ahli PLG adalah terbuka kepada Umum<br/>
                        Yuran Pendaftaran yang dikenakan adalah <b>RM 180.00</b> sahaja
                    </div>

                    <div className="SignUpDisclaimer">
                        <br/>
                        * Selepas anda mengisi borang ini, link pembayaran akan dihantar melalui email<br/>
                    </div>

                    <div className="SignUpButton">
                    <Link to ="/CustForm/#BackButtonHome"><a href='#BackButtonHome'><button className="RegisterNowButt">Daftar Sekarang</button></a></Link>
                    </div>
                    <a className="ChangeCredButt" onClick={() => submitVerifyEmail()}>Ingin Mengubah Maklumat anda? Klik Disini !</a>
                </div>
            </div>

            <div className="UnderDevelopment" id="Develop">
                <div className="DevContainer">
                    <div className="IconDevelopment ImageFull"/>
                    <div className="DevText">
                        Bahagian ini masih dalam proses dibaikpulih<br/>
                    </div>
                    <div className="DevDesc">
                        Konten Baharu akan ditambah dalam masa yang terdekat<br/>
                        Terima Kasih atas Kesabaran Anda 
                    </div>
                </div>
            </div>

            <div className="footerMain">
                <div className="footerHoldCont">
                    <div className='LogoImage MainLogo'/>
                    <div className="footerContent">
                        <div className="ListMenuFooter">
                            <div className='ListMenuFTitle'>Pautan Navigasi</div>
                            <ul className='ListFooter'>
                            <li><a href="#AboutUs">Tentang Kami</a></li>
                            <li><a href="#Develop">Carta Organisasi</a></li>
                            <li><a href="#Develop">Merchandise</a></li>
                            <li><a href="#News&Act">Berita & Aktiviti</a></li>
                            <li><a href="#Register">Daftar Ahli</a></li>
                            </ul>
                        </div>

                        <div className="OfficeLocation">
                            <div className='ListMenuFTitle'>Lokasi Pejabat</div>
                            <div className='LocationDesc'>
                                40-1, Jalan S2 F1 Garden Homes,
                                Seremban 2, 70300 Seremban,
                                Negeri Sembilan 
                            </div>
                        </div>

                        <div className="SocialMedia">
                            <div className='ListMenuFTitle'>Media Sosial</div>
                            <ul className='ListSocialMedia'>
                                <li><a href="https://www.facebook.com/PakisMalaysia" target="__blank"><BsFacebook/> Pertubuhan Lima Generasi HQ</a></li>
                                <li><a href="https://wa.me/+60178877115" target="__blank"><BsFillTelephoneFill/> 017-8877115</a></li>
                                <li><BsEnvelope/> admin@plgmalaysia.com</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="CopyrightDisclaim">
                    <img className='LogoContClic ImageFull' src={'./image/miscellanous/Clictec.png'} />
                    <div className='LogoDesc'>
                        Laman Web ini dikendalikan oleh ClicTec Solution 
                    </div>
                </div>
            </div>
        </div>
    );
};
  
  export default Form;
  
