import './ListAdmin.css';
import '../main.css';
import $ from 'jquery';
import { 
  useReactTable, 
  getCoreRowModel, 
  getFilteredRowModel,
  flexRender, 
  getPaginationRowModel } from '@tanstack/react-table';
  import ReactDOMServer from 'react-dom/server';
import * as React from 'react';
import { 
  BsXLg,
  BsFillPencilFill,
  BsFillTrashFill,
  BsDoorOpenFill,
  BsSearch,
  BsXSquareFill,
  BsFillPeopleFill,
  BsFillPersonLinesFill,
  BsCheck,
  BsFillPersonPlusFill,
  BsFillGearFill,
  BsTrashFill,
  BsFillPostcardFill,
  BsLockFill,
  BsFillPersonFill,
  BsPinMap,
  BsPlus
} from "react-icons/bs";
import { useNavigate} from 'react-router-dom';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from 'axios';

function ListApplicant() {
  let navigate = useNavigate();
  const [filter,setFilter] = React.useState('');
  const [dataAds,setDataAds] = React.useState({});
  const [privi,setPrivi] = React.useState();
  const [filterApplicant, setFilterApplicant] = React.useState("");
  const [filterState, setFilterState] = React.useState("");
  var dataAdmin = new FormData();

  React.useEffect(() => {
    searchData()

    $('input').keypress(function(e){
        var txt = String.fromCharCode(e.which);
        if (!txt.match(/[A-Za-z0-9&./']/)) {
            return false;

            if (e.which !== 32) {
                return false;
            }
        }
    })
  },[])

  const searchData = () => {
    var dataAdmin = new FormData();

    if(sessionStorage.getItem('tokenAuth') != null){
      dataAdmin.append('jwt_auth',sessionStorage.getItem('tokenAuth'));
      axios.post(process.env.REACT_APP_URL_DATABASE + 'plgsystem/Admin/viewAdmin.php',dataAdmin).then(resAdmin => {
        if(resAdmin.status == 200){
          setDataAds(resAdmin.data)
          sessionStorage.setItem("AdminState",resAdmin.data.state)
          sessionStorage.setItem("ADMINID",resAdmin.data.icNumber)
          sessionStorage.setItem("AdminPrivi",resAdmin.data.privi)
        }
      })
    } else {
      navigate('/LoginPage')
    }
  }

  const dumpData = (props,element) => {
    var dumpDataAdmin = new FormData();
    dumpDataAdmin.append("ADMINID",props.row.original.ADMINID)
    axios.post(process.env.REACT_APP_URL_DATABASE + 'plgsystem/Admin/deleteData.php',dumpDataAdmin).then(resCust => {
      if(resCust.status == 200){
        withReactContent(Swal).fire({
          position: "top-end",
          html : iconContainer("Delete"),
          showConfirmButton: false,
          customClass: {
              container:"swalContainerForm",
              popup:"swalPopupForm",
              title:"swalTitleForm"
          },
          timerProgressBar: true,
          timer:3000
      })
      $('body').removeClass('swal2-height-auto')
        searchData()
      }
    })
  }

  const columns = [
    {
      accessor : 'FullName',
      header : "Nama Penuh",
      cell : (props) => <p>{props.row.original.ADMINNAME}</p>
    },
    {
      accessor : 'ICNumber',
      header : "No. Kad Pengenalan",
      cell : (props) => <p>{props.row.original.ADMINICNUM}</p>
    },
    {
      accessor : 'State',
      header : "Negeri",
      cell : (props) => <center className={"ListApplicant_"+ props.row.original.ADMINSTATE}>{props.row.original.ADMINSTATE}</center>
    },
    {
      accessor : 'Action',
      header : "Action",
      cell : (props) => (
        <div className='ViewApplicant'>
          <BsFillTrashFill className='DumpData' id={props.row.id} onClick={(e) => dumpData(props,e.target)}/>
          <BsFillPencilFill className='EditData' id={props.row.id} onClick={(e) => editSwal(props,e.target)}/>
        </div>
      )      
    }
  ];

  const filteredData = React.useMemo(() => {
    if (!filter && !filterApplicant && !filterState) {
      return dataAds;
    } else {
      return dataAds.filter(row => {
        // Apply text filter to all values
        const textMatch = Object.values(row).some(value => {
          return String(value).toLowerCase().includes(filter.toLowerCase());
        });
        
        // Apply select filter to a specific field, e.g., 'Subscription'
        const selectMatch = filterApplicant === "" || row.Status === filterApplicant;
        const selectState = !filterState|| row.State === filterState;
        return textMatch && selectMatch && selectState;
      });
    }
  }, [filter, filterApplicant,filterState, dataAds]);

  const [pagination, setPagination] = React.useState({
    pageIndex: 0, //initial page index
    pageSize:10, //default page size
  });
  
  const tableApps = useReactTable({
    data : filteredData,
    columns,
    filter,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    getFilteredRowModel: getFilteredRowModel(),
    state : {
      pagination,
      globalFilter: filter
    }
  });

  const editSwal = (props,e) => {
    var editAdmin = new FormData();
    withReactContent(Swal).fire({
      title: "Edit "+ props.row.original.ADMINNAME + " Account",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Tukar Maklumat" ,
      disableConfirmButton:true,
      cancelButtonText: "Cancel",
      customClass : {
        actions : "editSwalAdmins",
        confirmButton : "confirmEditAdmins"
      },
      html: editElement()
    }).then((result) => {
      if(result.isConfirmed){
        var newAdminIC = "";
        editAdmin.append('ADMINID',props.row.original.ADMINID)
        $('.CPCont').children(":odd").map((i,value) =>{
          if($(value).attr('id') != undefined){
            if($(value).attr('id') == "ICNum"){
              newAdminIC = $(value).val()
            }

            editAdmin.append($(value).attr('id'),$(value).val())
          }
        })

        axios.post(process.env.REACT_APP_URL_DATABASE + 'plgsystem/Admin/updateAdmin.php',editAdmin).then(resAdmin => {
          if(resAdmin.status == 200){
            withReactContent(Swal).fire({
              position: "top-end",
              html : iconContainer("Update"),
              showConfirmButton: false,
              customClass: {
                  container:"swalContainerForm",
                  popup:"swalPopupForm successMessage",
                  title:"swalTitleForm"
              },
              timerProgressBar: true,
              timer:3000
          })
          $('body').removeClass('swal2-height-auto')
            searchData()
          }
        })
      }
    })

    $('.confirmEditAdmins').prop('disabled',true)
    $('body').removeClass('swal2-height-auto')
  }

  const showSwal = () => {
    var editCurrentAdmin = new FormData();
    withReactContent(Swal).fire({
      title: "Edit My Information",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Change My Credentials",
      cancelButtonText: "Cancel",
      customClass:{
        actions : "editSwalAdmins",
        confirmButton:"confirmEditAdmins"
      },
      html: editCurrentElement()
    }).then((result) => {
      if(result.isConfirmed){
      var newAdminIC = "";
      editCurrentAdmin.append('ADMINID',sessionStorage.getItem("ADMINID"))
      editCurrentAdmin.append("AdminPrivi",sessionStorage.getItem("AdminPrivi"))
      editCurrentAdmin.append("AdminState",sessionStorage.getItem("AdminState"))
      $('.CPCont').children(":odd").map((i,value) =>{
        if($(value).attr('id') != undefined){
          if($(value).attr('id') == "ICNum"){
            newAdminIC = $(value).val()
          }

          editCurrentAdmin.append($(value).attr('id'),$(value).val())
        }
      })

      axios.post(process.env.REACT_APP_URL_DATABASE + 'plgsystem/Admin/updateCurrentAdmin.php',editCurrentAdmin).then(resAdmin => {
        if(resAdmin.status == 200){
          sessionStorage.setItem("ADMINID",newAdminIC)
          withReactContent(Swal).fire({
            position: "top-end",
            html : iconContainer("Update"),
            showConfirmButton: false,
            customClass: {
                container:"swalContainerForm",
                popup:"swalPopupForm successMessage",
                title:"swalTitleForm"
            },
            timerProgressBar: true,
            timer:3000
        })
        $('body').removeClass('swal2-height-auto')
          searchData()
        }
      })
      }
    })

    $('.confirmEditAdmins').prop('disabled',true)
    $('body').removeClass('swal2-height-auto')
  }

  const showAdmin = () => {
    const iconString = ReactDOMServer.renderToString(<BsFillPostcardFill/>);
    const iconLock = ReactDOMServer.renderToString(<BsLockFill />);
    const iconAdmin = ReactDOMServer.renderToString(<BsFillPersonFill />);
    var addAdmin = new FormData();
    withReactContent(Swal).fire({
      title: "New Admin Credentials",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Tambah Admin",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton:"confirmEditAdmins"
      },
      html: editElement()
    }).then((result) => {
      if(result.isConfirmed){
      $('.CPCont').children(":odd").map((i,value) =>{
        if($(value).attr('id') != undefined){
          addAdmin.append($(value).attr('id'),$(value).val())
        }
      })

      axios.post(process.env.REACT_APP_URL_DATABASE + 'plgsystem/Admin/addAdmin.php',addAdmin).then(resAdmin => {
        if(resAdmin.status == 200){
          withReactContent(Swal).fire({
            position: "top-end",
            html : iconContainer("Add"),
            showConfirmButton: false,
            customClass: {
                container:"swalContainerForm",
                popup:"swalPopupForm successMessage",
                title:"swalTitleForm"
            },
            timerProgressBar: true,
            timer:3000
        })
        $('body').removeClass('swal2-height-auto')
          searchData()
        }
      })
      }
    })
    $('.confirmEditAdmins').prop('disabled',true)
    $('body').removeClass('swal2-height-auto')
  }
    
  return (
    <div className="LogContainer">
      <div className="NavigationCont">
      <div className="headCont">
        <div className='ContImage'>
          <div className="LogoImage"/>
          <div className="LogoTitle">Pertubuhan Lima <br/> Generasi Malaysia</div>
        </div>
        <ul className="ListMenu">
        <li><Link to={{pathname: '/Admin/ListApplicant'}}><BsFillPersonLinesFill /> <div>List Of Applicant</div></Link></li>
        <li className='Selected'><BsFillPersonFill /> <div>List Admin</div></li>
        <li onClick={(e) => {
          showSwal(); 
        }}><BsFillGearFill/> <div>Change Password</div></li>
        </ul>
        </div>

        <div className="LogoutButton">
        {/* <div className="StorageContainer">
            <div className="UsedStorage">30.00GB</div>
            <progress value="32" max="100"/>
            <div className="AvailableStorage">200.00GB</div>
          </div> */}
          <button onClick={() => {
            navigate('/LoginPage');
            sessionStorage.clear();
          }}><BsDoorOpenFill /> Logout</button>
          <div className='Copyright'>Managed by © ClicTec Solution</div>
        </div>
      </div>

      <div className='ContentContainer'>
        <div className='TableCont TableAdmin'>
        <div className="ContainerTableSearch">
        <div className="OptionController">
          <div className='SearchContainer'>
            <BsSearch className='SearchImage'/>
            <input type='text' placeholder="Cari Maklumat Admin" value={filter} onChange={e => setFilter(e.target.value)}/>
          </div>

          <div className="SelectFilter">
            <button className="IconFilter ButtonAddAdmin" onClick={() => showAdmin()}><BsPlus/> Tambah Admin</button>
          </div>
          
        </div>
          <table className='TableApps'>
            {tableApps.getHeaderGroups().map(headerGroup => <tr key={headerGroup.id}>
              {headerGroup.headers.map((
                header => <th key={header.id}>
                  {header.column.columnDef.header}
                </th>
              ))}
            </tr>)}
            {
              tableApps.getRowCount() > 0 ? (
                tableApps.getRowModel().rows.map(row => (
                    <tr key={row.id}>
                        {row.getVisibleCells().map(cell => (
                            <td key={cell.id}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        ))}
                    </tr>
                ))
            ) : (
              <tr>
                <td colSpan='5' className="emptyTable">
                  <BsXSquareFill/><br/><br/>
                  Tiada data di dalam sistem <br/>
                  atau<br/>
                  Data yang anda cari tiada di dalam sistem
                </td>
              </tr>
            )} 
          </table>
          </div>
          
          <div className='ButtonController'>
            <button
              onClick={() => tableApps.firstPage()}
              disabled={!tableApps.getCanPreviousPage()}
            >
              {'<<'}
            </button>
            <button
              onClick={() => tableApps.previousPage()}
              disabled={!tableApps.getCanPreviousPage()}
            >
              {'<'}
            </button>
            <button
              onClick={() => tableApps.nextPage()}
              disabled={!tableApps.getCanNextPage()}
            >
              {'>'}
            </button>
            <button
              onClick={() => tableApps.lastPage()}
              disabled={!tableApps.getCanNextPage()}
            >
              {'>>'}
            </button>

            <select
            value={tableApps.getState().pagination.pageSize}
            onChange={e => {
              tableApps.setPageSize(Number(e.target.value))
            }}
          >
            {[10,20,30,40,50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          </div>
        </div>

        <div className="ApplicantContainer">
          <div className='OptionContainer'>
            <div className='ICNumber TitleWindow'/>
            <BsXLg onClick={() => $('.ApplicantContainer').css('width','0%')}/>
          </div>
          <img className="ApplicantImage"/>
          <div className='ApplicantBasicData'>
            <table className='ApplicantDataTable'>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

function editElement(){
  const checkPass = () => {
    var checkValidate = 0;

    $('.CPCont').children(":odd").map((i,value) =>{
      if($(value).val() != ""){
        checkValidate++;
      } else {
        checkValidate--
      }
    })

    if(checkValidate < 5){
      $('.FormValidation').html("Sila pastikan semua maklumat diisi")
      $('.confirmEditAdmins').prop('disabled',true)
    } else {
      var input1 = $($('.CPCont').children(":odd")[2]).val()
      var input2 = $($('.CPCont').children(":odd")[3]).val()
      if(input1 != input2){
        $('.FormValidation').html("Pastikan Kata Laluan adalah sama!")
        $('.confirmEditAdmins').prop('disabled',true)
      } else {
        $('.FormValidation').html("Kata Laluan adalah Sama")
        $('.confirmEditAdmins').prop('disabled',false)
      }
    }
  } 

  return (
    <div class='ChangePassword'>
      <div className="CPCont">
        <label><BsFillPersonLinesFill/></label>
        <input type='text' id="FullName" onChange={() => checkPass()} placeholder="Nama Penuh"/>
      </div>

      <div className="CPCont">
        <label><BsFillPostcardFill/></label>
        <input type='number' id="ICNum" onChange={() => checkPass()} placeholder='No. Kad Pengenalan'/>
      </div>

      <div className="CPCont">
      <label><BsLockFill /></label>
      <input type='text' id="Password" className="CPNewPassword" onChange={() => checkPass()} placeholder="Kata Laluan"/>
      </div>

      
      <div className="CPCont">
      <label><BsLockFill /></label>
      <input type='text' className="CPRewritePassword" onChange={() => checkPass()} placeholder="Sahkan kata Laluan"/>
      </div>

      <div className="CPCont StateChange">
            <label><BsPinMap/></label>
            <select className="State" id="State" onChange={() => checkPass()}>
                <option selected value="">Pilih Negeri</option>
                <option value="Selangor">Selangor</option>
                <option value="Pahang">Pahang</option>
                <option value="Perak">Perak</option>
                <option value="Terengganu">Terengganu</option>
                <option value="Sabah">Sabah</option>
                <option value="Sarawak">Sarawak</option>
                <option value="Kedah">Kedah</option>
                <option value="Kelantan">Kelantan</option>
                <option value="Perlis">Perlis</option>
                <option value="NegeriSembilan">Negeri Sembilan</option>
                <option value="PulauPinang">Pulau Pinang</option>
                <option value="Melaka">Melaka</option>
                <option value="Johor">Johor</option>
            </select>
        </div>

        <div className="FormValidation">
          Pastikan Kata Laluan adalah sama!
        </div>
    </div>
  );
}

function editCurrentElement(){
  const checkPass = () => {
    var checkValidate = 0;

    $('.CPCont').children(":odd").map((i,value) =>{
      if($(value).val() != ""){
        checkValidate++;
      } else {
        checkValidate--
      }
    })

    if(checkValidate < 4){
      $('.FormValidation').html("Sila pastikan semua maklumat diisi")
      $('.confirmEditAdmins').prop('disabled',true)
    } else {
      var input1 = $($('.CPCont').children(":odd")[2]).val()
      var input2 = $($('.CPCont').children(":odd")[3]).val()
      if(input1 != input2){
        $('.FormValidation').html("Pastikan Kata Laluan adalah sama!")
        $('.confirmEditAdmins').prop('disabled',true)
      } else {
        $('.FormValidation').html("Kata Laluan adalah Sama")
        $('.confirmEditAdmins').prop('disabled',false)
      }
    }
  } 

  return (
    <div class='ChangePassword'>
      <div className="CPCont">
        <label><BsFillPersonLinesFill/></label>
        <input type='text' id="FullName" onChange={() => checkPass()} placeholder="Nama Penuh"/>
      </div>

      <div className="CPCont">
        <label><BsFillPostcardFill/></label>
        <input type='text' id="ICNum" onChange={() => checkPass()} placeholder='No. Kad Pengenalan'/>
      </div>

      <div className="CPCont">
      <label><BsLockFill /></label>
      <input type='text' id="Password" className="CPNewPassword" onChange={() => checkPass()} placeholder="Kata Laluan"/>
      </div>

      
      <div className="CPCont">
      <label><BsLockFill /></label>
      <input type='text' className="CPRewritePassword" onChange={() => checkPass()} placeholder="Sahkan kata Laluan"/>
      </div>

        <div className="FormValidation">
          Pastikan Kata Laluan adalah sama!
        </div>
    </div>
  );
}

function iconContainer(e) {
  if(e == "Update"){
    return (
      <div className='SwalIcon'>
        <BsCheck/>
        <div class='errorMessage successCont'>Maklumat telah Diubah</div>
      </div>
    );
  } else if(e == "Delete"){
    return (
      <div className='SwalIcon'>
        <BsTrashFill/>
        <div class='errorMessage'>Maklumat Telah Dipadam</div>
      </div>
    )
  } else if(e == "Add"){
    return (
      <div className='SwalIcon'>
        <BsCheck/>
        <div class='errorMessage'>Admin berjaya ditambah</div>
      </div>
    )
  } 
}

export default ListApplicant;
